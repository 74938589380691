import { Subject } from 'rxjs';

/**
 * Class used to cache any system's object for a limited time (Default: 10min)
 * If source is called when
 */
export class CachedObject<T> {
    private freshness: number;
    private expiry: number;
    private object: T;

    private percentToStaleSubject = new Subject<number>();
    public percentToStale$ = this.percentToStaleSubject.asObservable();

    /**
     * Object
     * @param expiry Number of milliseconds until expiration. Defaults to 10min
     */
    constructor(expiry: number = 10 * 60 * 1000) {
        this.expiry = expiry;
    }

    /**
     * Returns true if object is still fresh.
     */
    get isFresh(): boolean {
        return !!this.object && this.timeToExpire < this.expiry;
    }

    /**
     * Returns true if object is no longer fresh.
     */
    get isStale(): boolean {
        // convenience function
        return !this.isFresh;
    }

    /**
     * Force the instance to be stale
     */
    stale() {
        this.object = null;
    }

    /**
     * Gets the source object or null if the object has gone stale
     */
    get source(): T {
        if (this.isStale) {
            // fire expiry
            this.percentToStaleSubject.next(100);
            // cleanup and return null
            return (this.object = null);
        } else {
            // fire next expiry
            this.percentToStaleSubject.next((this.timeToExpire / this.expiry) * 100);

            return this.object;
        }
    }
    set source(object: T) {
        this.freshness = Date.now();
        this.object = object;
    }

    private get timeToExpire(): number {
        return Date.now() - this.freshness;
    }
}
