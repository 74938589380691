import { QueryList } from '@angular/core';

import { MatListOption, MatSelectionList } from '@angular/material/list';

import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { uniqBy } from 'lodash';

import { AdvancedMultiSelectItem } from 'src/app/_shared/advanced-column-setting/advanced-column-setting.model';
import { bufferDebounceTime, isNullOrUndefined } from 'src/app/_shared/shared.functions';

type OptionChanges = { value: AdvancedMultiSelectItem; selected: boolean };

export class SelectionListHelper {
    public readonly destroy$: Subject<void> = new Subject<void>();
    public readonly debounceTimeMs = 500;

    constructor(
        private matSelectionList: MatSelectionList,
        private matListOption: QueryList<MatListOption>,
        private selectedOptions$: BehaviorSubject<AdvancedMultiSelectItem[]>
    ) {}

    public observeSelectionChanges(callback: (items: AdvancedMultiSelectItem[]) => void): void {
        this.matSelectionList?.selectionChange
            .pipe(
                bufferDebounceTime(this.debounceTimeMs),
                map(listChanges => {
                    const options: OptionChanges[] = listChanges
                        .map(changes => changes.options)
                        .reduce((values, value) => [...values, ...value], [])
                        .map(option => ({ value: option.value, selected: option.selected }));

                    let selectedOptions: AdvancedMultiSelectItem[] = uniqBy(this.selectedOptions$.value, 'value');

                    selectedOptions = selectedOptions.filter(option => {
                        const index = options.findIndex(item => item.value.value === option.value);
                        return index === -1 ? true : options[index].selected;
                    });
                    selectedOptions.push(...options.filter(option => option.selected).map(option => option.value));

                    return uniqBy(selectedOptions, 'value');
                }),
                takeUntil(this.destroy$)
            )
            .subscribe(values => callback(values));
    }

    public observeMatOptionDOMChanges(): void {
        this.matListOption?.changes
            .pipe(debounceTime(0), takeUntil(this.destroy$))
            .subscribe(() => this.markSelectedMatListOptions());
    }

    public markSelectedMatListOptions(): void {
        const selectedOptions = this.selectedOptions$.value;
        if (isNullOrUndefined(selectedOptions) || selectedOptions.length === 0) {
            this.matSelectionList?.selectedOptions.clear();
            return;
        }

        const selectOptions =
            this.matListOption?.filter(item => {
                if (isNullOrUndefined(item.value)) {
                    return false;
                }

                const index = selectedOptions.findIndex(i => i.value === item.value.value);
                return index !== -1;
            }) || [];
        this.matSelectionList?.selectedOptions.clear();
        this.matSelectionList?.selectedOptions.select(...selectOptions);
    }
}
