import { ComponentRef } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

import { isOpenOrResolvedCasesRoute } from 'src/app/ucm/cases/models';
import { BaseCasesComponent } from 'src/app/ucm/cases/pages/cases/base-cases/base-cases.component';

export const SUPPRESS_ROUTE_REUSE = 'suppressRouteReuse';
export const SHOULD_SAVE_ROUTE = 'shouldSaveRoute';

export class TrdsRouteReuseStrategy extends BaseRouteReuseStrategy {
    private ucmDetachedRouteHandle: DetachedRouteHandle | null = null;

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if (future.data[SUPPRESS_ROUTE_REUSE]) {
            return false;
        }

        return super.shouldReuseRoute(future, curr);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (route.data[SHOULD_SAVE_ROUTE]) {
            if (this.isOpenOrResolvedCasesRoute(route)) {
                return !!this.ucmDetachedRouteHandle;
            }
        }

        if (!this.isAnyUcmCasesRoute(route)) {
            if (this.ucmDetachedRouteHandle && 'componentRef' in this.ucmDetachedRouteHandle) {
                (this.ucmDetachedRouteHandle.componentRef as ComponentRef<BaseCasesComponent>)?.destroy();
            }
            this.ucmDetachedRouteHandle = null;
        }

        return super.shouldAttach(route);
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (route.data[SHOULD_SAVE_ROUTE]) {
            return true;
        }

        return super.shouldDetach(route);
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        if (this.isOpenOrResolvedCasesRoute(route)) {
            this.ucmDetachedRouteHandle = detachedTree;
            return;
        }

        super.store(route, detachedTree);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if (this.isOpenOrResolvedCasesRoute(route)) {
            return this.ucmDetachedRouteHandle;
        }

        return super.retrieve(route);
    }

    private isOpenOrResolvedCasesRoute(route: ActivatedRouteSnapshot): boolean {
        return isOpenOrResolvedCasesRoute(route.routeConfig.path);
    }

    private isAnyUcmCasesRoute(route: ActivatedRouteSnapshot): boolean {
        return route.routeConfig?.path?.includes('cases');
    }
}
