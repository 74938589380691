import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule,
    MomentDateAdapter
} from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { TrdsRouteReuseStrategy } from 'src/app/_services/route-reuse/trds-route-reuse.strategy';
import { VisibilitiesService } from 'src/app/_services/visibilities/visibilities.service';

import { TokenInterceptor } from './auth/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppInitService } from './app-init.service';
import { GlobalModule } from './_global/global.module';
import { ErrorHandlerService } from './_error/error-handler.service';
import { UserService } from './_services/users/user.service';
import { RetryInterceptor } from './_interceptors/retry.interceptor';
import { PendoService } from './_services/pendo/pendo.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LaunchdarklyFeatureFlagService } from './_services/launchdarkly-feature-flag/launchdarkly-feature-flag.service';
import { LoadingInterceptor } from './_interceptors/loading.interceptor';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from 'src/environments/environment';
import { OktaAuthModule } from '@okta/okta-angular';
import { OKTA_MODULE_CONFIG } from './auth/auth.model';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        GlobalModule,
        MatDialogModule,
        MatSnackBarModule,
        MatMomentDateModule,
        OktaAuthModule.forRoot(OKTA_MODULE_CONFIG),
        environment.production ? [] : AkitaNgDevtools.forRoot()
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (appInit: AppInitService, userService: UserService, pendoService: PendoService) => {
                return () =>
                    appInit
                        .initApp()
                        .then(() => userService.initUser())
                        .then(() =>
                            pendoService.initialize({
                                user$: userService.user$,
                                solidusClientId$: userService.solidusClientId$
                            })
                        );
            },
            deps: [AppInitService, UserService, PendoService, LaunchdarklyFeatureFlagService, VisibilitiesService],
            multi: true
        },
        /**
         * The default implementation of ErrorHandler prints error messages to the console.
         * To intercept error handling, we provide a custom exception handler that replaces
         * this default as appropriate for our app.
         */
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, strict: true } },
        MomentDateAdapter,
        { provide: RouteReuseStrategy, useClass: TrdsRouteReuseStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
