import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';

import { DateRange, MatCalendar } from '@angular/material/datepicker';

import { Moment } from 'moment';

@Component({
    selector: 'trds-time-range-inputs',
    templateUrl: './time-range-inputs.component.html',
    styleUrls: ['./time-range-inputs.component.scss']
})
export class TimeRangeInputsComponent implements OnInit {
    @HostBinding('class') hostClass = 'trds-time-range-inputs';

    @Output() created = new EventEmitter<TimeRangeInputsComponent>();

    public timeFrom = '00:00';
    public timeTo = '23:59';

    constructor(private calendar: MatCalendar<Moment>) {}

    ngOnInit(): void {
        this.initTimes();

        this.created.emit(this);
    }

    get getHoursFrom(): number {
        return +this.sliceHours(this.timeFrom);
    }

    get getMinutesFrom(): number {
        return +this.sliceMinutes(this.timeFrom);
    }

    get getHoursTo(): number {
        return +this.sliceHours(this.timeTo);
    }

    get getMinutesTo(): number {
        return +this.sliceMinutes(this.timeTo);
    }

    private initTimes() {
        const dateRange: DateRange<Moment> = this.calendar.selected as DateRange<Moment>;
        const getTimeFormat = (date: Moment) => date.format('HH:mm');

        if (dateRange.start) {
            this.timeFrom = getTimeFormat(dateRange.start);
        }
        if (dateRange.end) {
            this.timeTo = getTimeFormat(dateRange.end);
        }
    }

    private sliceHours(time: string): string {
        return time.slice(0, 2);
    }

    private sliceMinutes(time: string): string {
        return time.slice(3, 5);
    }
}
