import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WindowOpenService {
    public open(url: string, useNewTab = true): void {
        window.open(url, useNewTab ? '_blank' : '_self');
    }
}
