<mat-selection-list class="list">
    <ng-container *ngIf="isLoading$ | async; else optionsLoaded">
        <mat-list-option class="no-checkbox-option">
            <mat-spinner class="loading-indicator" diameter="16"></mat-spinner>
        </mat-list-option>
    </ng-container>
    <ng-template #optionsLoaded>
        <ng-container *ngIf="showLoadingError$ | async; else showOptions">
            <div class="error-container">Error occurred during loading. Please, try again later.</div>
        </ng-container>
        <ng-template #showOptions>
            <div class="list-option">
                <mat-checkbox
                    color="primary"
                    class="select-all"
                    [indeterminate]="isSelectedAllIndeterminate$ | async"
                    [checked]="isSelectedAllChecked$ | async"
                    [disabled]="isSelectedAllDisabled$ | async"
                    (change)="onSelectedAllClick()"
                >
                    <span class="label">All</span>
                </mat-checkbox>
            </div>
            <mat-list-option
                *ngFor="let value of values$.asObservable() | async"
                [checkboxPosition]="'before'"
                [value]="value"
            >
                {{ value.name }}
            </mat-list-option>
        </ng-template>
    </ng-template>
</mat-selection-list>
