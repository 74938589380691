<mat-toolbar class="header-toolbar">
    <div class="breadcrumbs">
        <div class="sub" *ngIf="pages.length > 1">
            <h3 (click)="routeTo(pages[0])" tabindex="0" (keydown.enter)="routeTo(pages[0])">
                {{ pages[0].title }}
            </h3>
        </div>

        <div *ngFor="let page of subPages" class="sub">
            <h3 (click)="routeTo(page)" tabindex="0" (keydown.enter)="routeTo(page)">{{ page.title }}</h3>
        </div>
        <div class="back-container" *ngIf="pages.length > 1">
            <button
                mat-stroked-button
                class="back"
                aria-label="back"
                color="primary"
                tabindex="2"
                (click)="routeToParent()"
                (keydown.enter)="routeToParent()"
            >
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>

        <h1
            class="main"
            tabindex="0"
            *ngIf="mainPage"
            #titleRef
            (keydown)="focusOnNavBar($event)"
            [attr.auto_id]="autoIdTitle"
            [ngxTippy]="mainPage.tooltip"
            tippyClassName="trds-tooltip"
            [class.hovered]="mainPage.icon || mainPage.tooltip"
        >
            <mat-icon *ngIf="mainPage.icon" [svgIcon]="mainPage.icon"></mat-icon>
            {{ mainPage.title }}
        </h1>
    </div>

    <div>
        <trds-help-menu *ngIf="zendeskEnabled"></trds-help-menu>
        <button mat-button [matMenuTriggerFor]="userMenu" class="user-btn" color="primary" auto_id="btn_LoggedInUser">
            <mat-icon fontSet="material-icons-outlined" class="user-icon">account_circle</mat-icon>
            <span class="name">{{ user ? user.name : 'Unknown User' }}</span>
        </button>

        <mat-menu #userMenu="matMenu">
            <button
                *ngIf="notificationSettingsEnabled"
                mat-menu-item
                (click)="goToNotificationSettingsPage()"
                auto_id="btn_NotificationSetting"
            >
                Notification Settings
            </button>
            <button mat-menu-item (click)="logout()" auto_id="listBox_LoggedInUserItems">Logout</button>
        </mat-menu>
    </div>
</mat-toolbar>
