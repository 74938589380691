import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'trds-notification-snackbar',
    templateUrl: './notification-snackbar.component.html',
    styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackBarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<NotificationSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public message: string
    ) {}
}

export enum NotificationType {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info'
}

interface INotificationOption {
    duration: number | undefined;
    panelClass: string;
}

interface INotificationOptions {
    success: INotificationOption;
    error: INotificationOption;
    info: INotificationOption;
}

export const NotificationOptions: INotificationOptions = {
    success: {
        duration: 6000,
        panelClass: 'success-background'
    },
    error: {
        duration: undefined,
        panelClass: 'warn-background'
    },
    info: {
        duration: undefined,
        panelClass: 'info-background'
    }
};
