import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsufficientPermissionPage } from './insufficient-permission/insufficient-permission.page';
import { LayoutModule } from 'src/app/layout/layout.module';
import { PageNotFoundPage } from './page-not-found/page-not-found.page';

@NgModule({
    declarations: [InsufficientPermissionPage, PageNotFoundPage],
    imports: [CommonModule, LayoutModule],
    exports: [InsufficientPermissionPage, PageNotFoundPage]
})
export class GlobalModule {}
