import { Inject, Injectable, OnDestroy } from '@angular/core';
import { initialize, LDClient, LDContext } from 'launchdarkly-js-client-sdk';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

import { domainsToEnvironmentMapper, FlagModel, LD_STORE_KEY } from './launchdarkly.model';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../users/user.service';
import { takeUntil } from 'rxjs/operators';
import { DetailedUser } from 'src/app/_shared/shared.models';

@Injectable({
    providedIn: 'root'
})
export class LaunchdarklyFeatureFlagService implements OnDestroy {
    flagStore: FlagModel = {};
    private destroy$ = new Subject<void>();

    flagChange: BehaviorSubject<FlagModel> = new BehaviorSubject<FlagModel>(this.flagStore);
    client: LDClient;

    constructor(@Inject(DOCUMENT) private document: Document, private userservice: UserService) {
        this.loadFlagsFromLocalStorage();
        this.userservice.user$.pipe(takeUntil(this.destroy$)).subscribe(user => {
            this.client = this.initLaunchDarklyClient(user);
            if (this.client) {
                this.onClientReady();
                this.onFlagChange();
            }
        });
    }

    onClientReady(): void {
        this.client.on('initialized', () => {
            this.flagStore = { ...this.client.allFlags() };

            localStorage.setItem(LD_STORE_KEY, JSON.stringify(this.flagStore));

            this.flagChange.next(this.flagStore);
        });
    }

    onFlagChange(): void {
        this.client.on(`change`, flags => {
            Object.keys(flags).forEach(key => {
                flags[key] = flags[key].current;
            });

            this.flagStore = { ...this.flagStore, ...flags };

            localStorage.setItem(LD_STORE_KEY, JSON.stringify(this.flagStore));

            this.flagChange.next(this.flagStore);
        });
    }

    initLaunchDarklyClient(user?: DetailedUser): LDClient {
        const context: LDContext = {};

        const subdomain = this.getUserSubDomain();

        const launchdarklyKeys: { sdk_key: string; client_side_id: string } = this.getLaunchdarklyKeys();

        if (!user) {
            context.key = ' ';
            context.custom = {
                subDomain: subdomain,
                solidusClientId: ' '
            };
        } else {
            context.key = user.email;
            context.email = user.email;
            context.custom = {
                subDomain: subdomain,
                solidusClientId: user.solidusClientId
            };
        }

        return initialize(launchdarklyKeys.client_side_id, context);
    }

    getUserSubDomain(): string {
        const currentRout = this.document.location.hostname;

        const splittedHostName: string[] = currentRout.split('.');

        return splittedHostName.length > 1 ? splittedHostName[1] : splittedHostName[0];
    }

    getLaunchdarklyKeys(): { sdk_key: string; client_side_id: string } {
        const subDomain: string = this.getUserSubDomain();

        const env: string = domainsToEnvironmentMapper[subDomain];

        const launchdarklyKyes: { sdk_key: string; client_side_id: string } = environment.launchdarkly[env];

        return launchdarklyKyes;
    }

    loadFlagsFromLocalStorage(): void {
        const flagsStr = localStorage.getItem(LD_STORE_KEY);

        if (flagsStr) {
            let flags: FlagModel = {};
            try {
                flags = JSON.parse(flagsStr);
            } catch (e) {
                localStorage.removeItem(LD_STORE_KEY);
            }

            this.flagStore = flags;
            this.flagChange.next(this.flagStore);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
