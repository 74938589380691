import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LoggingService } from 'src/app/_logging/logging.service';
import { getErrorMessage, isNullOrUndefined } from '../shared.functions';
import { HttpErrorResponse } from '@angular/common/http';

export type NotifyType = 'INFO' | 'ERROR' | 'WARNING';

export type NotifyConfig = {
    type: NotifyType;
    title: string;
    body: string;
    items?: string[];
    caption?: string;
    showCancel?: boolean;
    cancelButtonText?: string;
    continueButtonName: string;
    errorMessage?: string;
    awaitingObservable?: Observable<unknown>;
    parseErrorMessage?: (error: HttpErrorResponse) => string | undefined;
};

@Component({
    selector: 'trds-notify-dialog',
    templateUrl: './notify-dialog.component.html',
    styleUrls: ['./notify-dialog.component.scss']
})
export class NotifyDialogComponent implements OnDestroy {
    @HostBinding('class') class = 'trds-dialog';
    protected destroy$ = new Subject<void>();

    public pending = false;
    public showErrorMessage = false;
    public errorMessage = '';

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public config: NotifyConfig,
        public dialogRef: MatDialogRef<NotifyDialogComponent>,
        private log: LoggingService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    confirm(): void {
        this.pending = true;
        this.showErrorMessage = false;

        // await observable result if necessary
        if (this.config.awaitingObservable) {
            this.config.awaitingObservable.pipe(takeUntil(this.destroy$)).subscribe({
                next: res => {
                    this.dialogRef.close(res);
                },
                error: error => {
                    this.showErrorMessage = true;
                    this.pending = false;
                    this.errorMessage = this.getErrorMessageOrGetDefault(error);
                    this.log.error(
                        `There was a problem calling the server with ${this.config.title}. ${getErrorMessage(error)}`
                    );
                }
            });
        } else {
            this.dialogRef.close(true);
        }
    }

    private getErrorMessageOrGetDefault(error: HttpErrorResponse): string {
        if (isNullOrUndefined(this.config.parseErrorMessage)) {
            return this.config.errorMessage;
        }

        return this.config.parseErrorMessage(error) || this.config.errorMessage;
    }
}
