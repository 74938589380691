import { ReAuthService } from 'src/app/_services/re-auth/re-auth.service';

export class PreSaveValueStorage {
    public static readonly storageKey = 'preSavedValues';
    private readonly storage = sessionStorage;

    constructor(private reAuthService: ReAuthService) {}

    public setItem(key: string, value: string): void {
        const map = this.getStoredMap();
        map[key] = value;

        return this.storage.setItem(PreSaveValueStorage.storageKey, JSON.stringify(map));
    }

    public getItem(key: string): string | null {
        const map = this.getStoredMap();

        return map[key] || null;
    }

    public clearItem(key: string): void {
        if (this.reAuthService.isDialogOpened) {
            return;
        }

        const map = this.getStoredMap();
        delete map[key];

        return this.storage.setItem(PreSaveValueStorage.storageKey, JSON.stringify(map));
    }

    public clearAll(): void {
        if (this.reAuthService.isDialogOpened) {
            return;
        }

        return this.storage.removeItem(PreSaveValueStorage.storageKey);
    }

    public hasAnyValue(values: string[]): boolean {
        const keys: string[] = Object.keys(this.getStoredMap());
        return values.some(value => keys.some(key => key.includes(value)));
    }

    private getStoredMap(): { [key: string]: string } {
        const stringifierValues = this.storage.getItem(PreSaveValueStorage.storageKey) || JSON.stringify({});

        return JSON.parse(stringifierValues);
    }
}
