import { DetailedUser } from '../_shared/shared.models';
import { UserEnv, UserFlag } from '../_services/users/user.service';
import { OktaConfig } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

export type Auth = {
    token?: string;
    user?: DetailedUser;
    secretKey?: string;
    userId?: string;
    data?: string;
    solidusClientId?: string;
    [UserFlag.MakerChecker]?: boolean;
    [UserFlag.STRReport]?: boolean;
    [UserFlag.DexTradeAdvancedSearch]?: boolean;
    [UserEnv.BaseCurrency]?: string;
    settings?: {
        ftxColumnsEnabled?: boolean;
    };
    sso?: boolean;
    tenants?: TenantDto[];
};

export type SsoAuthInfo = {
    clientId: string | null;
    type?: SSOProviders;
    tenantId?: string;
    issuerDomain?: string;
};

export type OktaUserConfig = {
    clientId?: string;
    issuer: string;
    redirectUri?: string;
    scopes?: string[];
    pkce?: boolean;
};

export enum SSOProviders {
    AZURE = 'AZURE',
    OKTA = 'OKTA'
}

export enum Auth2FAStatus {
    VERIFYING = 'VERIFYING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

export enum LoginStep {
    EMAIL = 'EMAIL',
    PASSWORD = 'PASSWORD',
    TENANT = 'TENANT'
}

export interface TenantDto {
    id: string;
    name: string;
}

export const AUTH_TOKEN = 'token';
export const MULTI_TENANT_LOGIN_ERROR = 'Please verify account type and E-mail/password and try again.';
export const RESET_PASSWORD_NO_TENANTS_FOUND_ERROR = 'Unable to reset password. Contact your administrator.';
export const RESET_PASSWORD_LINK_INVALID_ERROR = 'Unable to reset password. Please try again.';
export const FORGOT_PASSWORD_LINK_INVALID_ERROR =
    'The e-mail verification link is invalid. Please click on Forgot password again.';

export const NO_AVAILABLE_MODULES = 'No available modules for ths user.';
export const FAILED_REDIRECTING_AFTER_LOGIN = 'Failed redirecting after login.';
export const FAILED_LOGIN_UNEXPECTED_ERROR = 'The system failed to login due to an unexpected error.';

const OKTA_AUTH_CONFIG: OktaUserConfig = {
    issuer: 'https://***.com/oauth2/***'
};
const oktaAuth = new OktaAuth(OKTA_AUTH_CONFIG);
export const OKTA_MODULE_CONFIG: OktaConfig = { oktaAuth };
