import { isNullOrUndefined } from 'src/app/_shared/shared.functions';

export class TransferRouteDataClass<T> {
    private readonly storage: Storage = localStorage;

    constructor(private readonly storeKey: string) {}

    public setData(data: T): void {
        this.storage.setItem(this.storeKey, JSON.stringify(data));
    }

    public getData(removeData = true): T | null {
        const dataStr: string = this.storage.getItem(this.storeKey);

        if (isNullOrUndefined(dataStr)) {
            return null;
        }

        if (removeData) {
            this.removeData();
        }

        return JSON.parse(dataStr);
    }

    public removeData(): void {
        this.storage.removeItem(this.storeKey);
    }
}
