<div class="chips-container">
    <a
        tabindex="0"
        [class.accent]="isCustomRangeActive"
        class="link hidden-link"
        (click)="onDefaultPresetClick(ActivePresetType.CustomRange)"
        (keyDown.enter)="onDefaultPresetClick(ActivePresetType.CustomRange)"
        >Custom range</a
    >
    <a
        tabindex="0"
        [class.accent]="isSingleDayActive"
        class="link hidden-link"
        (click)="onDefaultPresetClick(ActivePresetType.SingleDay)"
        (keyDown.enter)="onDefaultPresetClick(ActivePresetType.SingleDay)"
        >Single day</a
    >
    <ng-container *ngFor="let preset of presets">
        <a
            tabindex="0"
            class="link"
            [class.accent]="preset === activePreset"
            (click)="onPresetClick(preset)"
            (keyDown.enter)="onPresetClick(preset)"
        >
            {{ preset }}
        </a>
    </ng-container>
</div>
