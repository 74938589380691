import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { DateAdapter } from '@angular/material/core';
import { DateRange, MatCalendar, MatDateRangePicker } from '@angular/material/datepicker';

import moment, { Moment } from 'moment';

export enum DatePickerPresets {
    Today = 'Today',
    Last1Day = 'Last 1 day',
    Last3Days = 'Last 3 days',
    Last7Days = 'Last 7 days',
    Last30Days = 'Last 30 days',
    Last90Days = 'Last 90 days'
}

export enum ActivePresetType {
    CustomRange,
    SingleDay,
    Preset
}

@Component({
    selector: 'trds-date-range-picker-presets',
    templateUrl: './date-range-picker-presets.component.html',
    styleUrls: ['./date-range-picker-presets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerPresetsComponent implements OnInit, OnDestroy {
    @Input() public readonly presets;
    @Input() hasTimePicker = false;

    @HostBinding('class') hostClass = 'trds-date-range-picker-presets';

    public activePreset: DatePickerPresets | null = null;
    public ActivePresetType = ActivePresetType;
    public activePresetType: ActivePresetType;

    private readonly getRangeFromPreset: { [key in DatePickerPresets]: () => DateRange<Moment> } = {
        [DatePickerPresets.Today]: () => this.getLastDaysRange(1),
        [DatePickerPresets.Last1Day]: () => this.getLastDaysRange(2),
        [DatePickerPresets.Last3Days]: () => this.getLastDaysRange(3),
        [DatePickerPresets.Last7Days]: () => this.getLastDaysRange(7),
        [DatePickerPresets.Last30Days]: () => this.getLastDaysRange(30),
        [DatePickerPresets.Last90Days]: () => this.getLastDaysRange(90)
    };
    private readonly destroy$ = new Subject<void>();

    constructor(
        private dateAdapter: DateAdapter<Moment>,
        private picker: MatDateRangePicker<Moment>,
        private calendar: MatCalendar<Moment>,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.activePreset = this.getActivePreset();
        this.activePresetType = this.getPresetType(this.activePreset);
        this.observeCalendarSelectionChanges();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public get isCustomRangeActive(): boolean {
        return this.activePresetType === ActivePresetType.CustomRange;
    }

    public get isSingleDayActive(): boolean {
        return this.activePresetType === ActivePresetType.SingleDay;
    }

    public get momentUtc(): Moment {
        return moment.utc();
    }

    public onPresetClick(preset: string): void {
        if (!(preset in this.getRangeFromPreset)) {
            return;
        }

        this.resetIfSelected();
        const { start, end } = this.getRangeFromPreset[preset]();
        this.picker.select(start);
        this.picker.select(end);
        this.activePreset = preset as DatePickerPresets;
        this.activePresetType = ActivePresetType.Preset;
        this.tryToClosePicker();
    }

    public onDefaultPresetClick(type: ActivePresetType) {
        this.resetIfSelected();
        this.picker.select(null);
        this.activePreset = null;
        this.activePresetType = type;
    }

    private resetIfSelected(): void {
        const dateRange: DateRange<Moment> = this.calendar.selected as DateRange<Moment>;
        if (dateRange?.start && !dateRange?.end) {
            this.picker.select(this.momentUtc);
        }
    }

    private getLastDaysRange(days: number): DateRange<Moment> {
        const today = this.momentUtc;
        const start = this.dateAdapter.addCalendarDays(today, -(days - 1));

        return new DateRange(start, today);
    }

    private getActivePreset(): DatePickerPresets | null {
        const { start, end } = this.calendar.selected as DateRange<Moment>;

        if (!start?.isValid() || !end?.isValid()) {
            return null;
        }

        for (const preset of Object.values(DatePickerPresets)) {
            if (preset in this.getRangeFromPreset) {
                const presetRange: DateRange<Moment> = this.getRangeFromPreset[preset]();
                if (this.areDatesEqual(this.calendar.selected as DateRange<Moment>, presetRange)) {
                    return preset as DatePickerPresets;
                }
            }
        }

        return null;
    }

    private observeCalendarSelectionChanges(): void {
        this.calendar.selectedChange.pipe(takeUntil(this.destroy$), debounceTime(100)).subscribe(date => {
            this.activePreset = null;
            if (this.activePresetType === ActivePresetType.SingleDay) {
                this.picker.select(date.endOf('day'));
                this.tryToClosePicker();
            } else {
                this.activePresetType = ActivePresetType.CustomRange;
            }

            this.cdr.markForCheck();
        });
    }

    private getPresetType(preset: DatePickerPresets): ActivePresetType {
        if (preset in this.getRangeFromPreset) {
            return ActivePresetType.Preset;
        }

        const dateRange: DateRange<Moment> = this.calendar.selected as DateRange<Moment>;
        if (dateRange.start?.isSame(dateRange.end, 'day')) {
            return ActivePresetType.SingleDay;
        }

        return ActivePresetType.CustomRange;
    }

    private areDatesEqual(first: DateRange<Moment>, second: DateRange<Moment>): boolean {
        return (
            first.start.isSame(second.start, 'year') &&
            first.start.isSame(second.start, 'month') &&
            first.start.isSame(second.start, 'day') &&
            first.end.isSame(second.end, 'year') &&
            first.end.isSame(second.end, 'month') &&
            first.end.isSame(second.end, 'day')
        );
    }

    private tryToClosePicker(): void {
        if (this.hasTimePicker) {
            return;
        }

        this.picker.close();
    }
}
