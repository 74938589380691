<div class="container">
    <div class="placeholder"></div>
    <div class="time-inputs">
        <div class="time-from">
            <span>From:</span>
            <input type="time" aria-label="time-from" [(ngModel)]="timeFrom" />
        </div>
        <div class="time-to">
            <span>To:</span>
            <input type="time" aria-label="time-to" [(ngModel)]="timeTo" />
        </div>
    </div>
</div>
