import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { AppConfigService } from '../app-config.service';
import { ReloadService } from '../_services/reload/reload.service';
import { UserService } from '../_services/users/user.service';
import { VisibilitiesService } from '../_services/visibilities/visibilities.service';
import { AuthGuard } from './loggedin.guard';

@Injectable({
    providedIn: 'root'
})
export class VisibilityGuard extends AuthGuard {
    constructor(
        protected visibilitiesService: VisibilitiesService,
        protected router: Router,
        protected authService: AuthService,
        protected userService: UserService,
        protected reloadService: ReloadService,
        protected appConfig: AppConfigService
    ) {
        super(router, authService, userService, reloadService);
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const moduleRootUrl = route.url[0].path;
        const auth$ = super.canActivate(route, state);
        const visibility$ = this.visibilitiesService.isVisible$(moduleRootUrl);
        const feature = route.data.feature;

        return combineLatest([auth$, visibility$]).pipe(
            map(([authorized, hasVisibility]) => {
                if (authorized && (!hasVisibility || this.appConfig.isFeatureDisabled(feature))) {
                    this.router.navigateByUrl('/insufficient-permission');
                    return false;
                }

                return authorized && hasVisibility;
            })
        );
    }
}
