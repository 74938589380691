import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { iif, Observable, throwError, timer } from 'rxjs';
import { retryWhen, switchMap } from 'rxjs/operators';

import { LoggingService } from '../_logging/logging.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
    private readonly maxRetryCount = 3;
    private readonly defaultDelayMs = 750;

    constructor(private log: LoggingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retryWhen(error =>
                error.pipe(
                    switchMap<HttpErrorResponse, Observable<number>>((error, retryCount) => {
                        if (!this.shouldRetryRequest(error)) {
                            return throwError(error);
                        }

                        return iif(
                            () => this.isRetryCountExceed(retryCount),
                            this.retryAfterDelay$(retryCount),
                            throwError(error)
                        );
                    })
                )
            )
        );
    }

    /*
        Checks if the retry count doesn't exceed maxRetryCount
     */
    private isRetryCountExceed(retryCount: number): boolean {
        return retryCount < this.maxRetryCount;
    }

    /*
     Checks if the http error status is 0 (zero)
  */
    private shouldRetryRequest(error: HttpErrorResponse): boolean {
        if (this.isLoggingServiceRequest(error)) {
            return false;
        }

        return error.status === 0;
    }

    /*
        Emits after calculated delay in order to retry incoming request
     */
    private retryAfterDelay$(retryCount: number): Observable<any> {
        const delayDuration = this.defaultDelayMs * Math.pow(2, retryCount);
        return timer(delayDuration);
    }

    private isLoggingServiceRequest(error: HttpErrorResponse): boolean {
        const loggingServicePaths = ['log/error', 'log/warn', 'log/info'];
        return loggingServicePaths.some(path => error.url.includes(path));
    }
}
