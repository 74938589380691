import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const baseUrl = 'auth/api/v1/support';

@Injectable({
    providedIn: 'root'
})
export class ZendeskHelpCenterService {
    constructor(private http: HttpClient) {}

    getJWTToken(name: string): Observable<any> {
        return this.http.get<any>(`${baseUrl}/zendesk-token/${name}`);
    }
}
