import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ReloadService } from '../_services/reload/reload.service';
import { UserService } from '../_services/users/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected userService: UserService,
        protected reloadService: ReloadService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this.authService.isLoggedIn()) {
            // wait for user data to be loaded before returning
            // makes sure we have all user info before loading the page
            return this.userService.user$.pipe(
                // user$ is a behavioral subject - filter out the initial value that is 'null'
                filter(user => user !== null),
                map(() => true)
            );
        }

        // If not logged prevent routing by return a observable with false
        return of(false).pipe(
            tap(() => {
                // then Redirect to login page and reload
                this.authService.logout();
                this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            })
        );
    }
}
