import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { LoggingService } from '../_logging/logging.service';
import { getErrorMessage } from '../_shared/shared.functions';
import { ReloadService } from '../_services/reload/reload.service';

type ErrorType = HttpErrorResponse | Error;

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    constructor(private loggingService: LoggingService, private reloadService: ReloadService, private router: Router) {}

    /**
     * Provides a hook for centralized exception handling.
     */
    handleError(error: ErrorType): void {
        // Log an error like the ErrorHandler do by default
        console.error((error as Error)?.stack);

        // Check if it is a chunk load error. Such kind of error occurs when a new version of app is deployed to the server
        if (this.isChunkLoadError(error)) {
            this.reloadService.reload();
        }
        // Check if it is an unhandled error from an HTTP response
        else if (this.isHttpError(error)) {
            this.handleHttpError(error as HttpErrorResponse);
        }
        // Otherwise handle application error
        else {
            this.handleAppError(error);
        }
    }

    private handleHttpError(error: HttpErrorResponse): void {
        this.loggingService.error(getErrorMessage(error));
    }

    private handleAppError(error: Error): void {
        this.loggingService.error(
            JSON.stringify(error, Object.getOwnPropertyNames(error)) + ` APP_URL: ${this.router.url}`
        );
    }

    private isChunkLoadError(error: ErrorType): boolean {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        return chunkFailedMessage.test(error.message);
    }

    private isHttpError(error: ErrorType): boolean {
        return error instanceof HttpErrorResponse;
    }
}
