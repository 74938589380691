import { Injectable } from '@angular/core';

import { Sort } from '@angular/material/sort';

import { cloneDeep } from 'lodash';

import { isNullOrUndefined } from 'src/app/_shared/shared.functions';
import { IColumnFilters } from 'src/app/tables-with-filters/shared.models';

export type TableStateType = 'filter_state' | 'sort_state';
export type TableStateTypePayload = IColumnFilters | Sort;

@Injectable({
    providedIn: 'root'
})
export class TableStateStorageService {
    private readonly storageMap = new Map<string, Map<TableStateType, TableStateTypePayload>>();

    getItem<T extends TableStateTypePayload>(tableKey: string, type: TableStateType, defaultValue?: T): T | null {
        const tableItem = this.storageMap.get(tableKey);
        if (tableItem && tableItem.get(type)) {
            return tableItem.get(type) as T;
        }

        return defaultValue ?? null;
    }

    setItem(tableKey: string, type: TableStateType, item: TableStateTypePayload): void {
        if (isNullOrUndefined(item)) {
            return;
        }

        let tableItem = this.storageMap.get(tableKey);
        if (isNullOrUndefined(tableItem)) {
            this.storageMap.set(tableKey, new Map());
        }
        tableItem = this.storageMap.get(tableKey);

        this.storageMap.set(tableKey, tableItem.set(type, item));
    }

    removeItemPropertyByName(tableKey: string, propName: string): void {
        const item: IColumnFilters = this.getItem<IColumnFilters>(tableKey, 'filter_state');

        if (isNullOrUndefined(item)) {
            return;
        }

        const newItem: IColumnFilters = cloneDeep(item);
        delete newItem[propName];

        this.setItem(tableKey, 'filter_state', newItem);
    }

    clear(): void {
        this.storageMap.clear();
    }
}
