import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SortModelItem } from 'ag-grid-enterprise';

import { MatExpansionPanel } from '@angular/material/expansion';
import { Sort } from '@angular/material/sort';

import { LoggingService } from 'src/app/_logging/logging.service';
import { isNullOrUndefined, tzHttpParam } from 'src/app/_shared/shared.functions';
import { Paginated } from 'src/app/_shared/shared.models';
import { TransferRouteDataClass } from 'src/app/_shared/transfer-route-data/transfer-route-data.class';
import { IGetOptionsRequest, prepareSearchPropertyHttpParams } from 'src/app/ag-grid-tables/shared/models.shared';
import {
    AdvancedSearchPagedParams,
    AlertAdvancedSearchPageParams,
    ApplicationAdvancedSearchPageParams,
    CaseAdvancedSearchPageParams,
    PaginatedSearchResults,
    PaginationFilter,
    TableType,
    SortFilter,
    AlertAdvancedSearchParams,
    CaseAdvancedSearchParams,
    ISuggestedSearchParams
} from '../advanced-search.models';
import { ExtendedCase, IExtendedCaseDTO } from '../../ucm/cases/models';
import { BaseAlert, IBaseAlertDTO } from '../../ucm/alerts/alerts.models';
import { Application } from '../../onboarding/models';
import { getAgHttpParams, getFiltersPayload, getHttpParams } from '../../tables-with-filters/shared.functions';
import { IColumnFilters } from 'src/app/tables-with-filters/shared.models';
import { AppConfigService } from '../../app-config.service';

export const baseUrl = 'advanced-search/api/v1/advanced-search'; // microserviceKey + pathPrefix
export const baseUrlV2 = 'advanced-search/api/v2'; // microserviceKey + pathPrefix
export const casesAdvancedSearchBaseUrl = 'ucm/api/v1/advanced-search/cases'; // microserviceKey + pathPrefix
export const alertsAdvancedSearchBaseUrl = 'ucm/api/v1/advanced-search/alerts'; // microserviceKey + pathPrefix
export const applicationAdvancedSearchBaseUrl = 'onboarding/api/v1/advanced-search/applications'; // microserviceKey + pathPrefix

export type EventType = 'TRANSACTION' | 'ORDER' | 'EXECUTION' | 'DEX_TRADE' | 'CASE';

export type DefaultSearchType = {
    [TableType.Alerts]?: AlertAdvancedSearchParams;
    [TableType.Cases]?: CaseAdvancedSearchParams;
};

@Injectable({
    providedIn: 'root'
})
export class AdvancedSearchService {
    private _onSearchCriteriaClick$ = new Subject<string>();

    private onSearchCriteriaClicked = false;
    private filtersPanel: MatExpansionPanel | null = null;
    private readonly RecordTypeUrl = {
        [TableType.Cases]: 'ucm/api/v1/advanced-search/cases'
    };
    public readonly transferRouteData = new TransferRouteDataClass<DefaultSearchType>('default_search_storage');
    public readonly setSuggestedSearch$ = new Subject<ISuggestedSearchParams>();

    constructor(private http: HttpClient, public log: LoggingService, private appConfigService: AppConfigService) {}

    get onSearchCriteriaClick$(): Observable<string> {
        return this._onSearchCriteriaClick$.asObservable();
    }

    get isExpanded(): boolean {
        return this.filtersPanel?.expanded ?? false;
    }

    getEventsWithFilters<Dto, Result>(
        pagination: PaginationFilter,
        sort: SortModelItem,
        filters: IColumnFilters,
        type: EventType,
        mapFunc: (dto: Paginated<Dto>) => Paginated<Result>
    ): Observable<Paginated<Result>> {
        const payloadFilters = cloneDeep(filters);

        return this.http
            .post<Paginated<Dto>>(
                `${baseUrlV2}/advanced-search`,
                { ...payloadFilters, type },
                {
                    params: getAgHttpParams(pagination, sort)
                }
            )
            .pipe(map<Paginated<Dto>, Paginated<Result>>(mapFunc));
    }

    public searchProperty(
        property: string,
        filters: IColumnFilters,
        type: EventType,
        params: IGetOptionsRequest
    ): Observable<string[]> {
        return this.http.post<string[]>(
            `${baseUrlV2}/search-property/${property}`,
            { ...filters, type },
            {
                params: prepareSearchPropertyHttpParams(params)
            }
        );
    }

    public exportEventsWithFilters(type: EventType, filters: IColumnFilters, sort: SortModelItem): Observable<Blob> {
        return this.exportAdvancedSearchDataV2({ ...filters, type }, `${baseUrlV2}/advanced-search`, sort, false);
    }

    public getDataWithFilters<T extends ExtendedCase>(
        type: TableType,
        pagination: PaginationFilter,
        sort: Sort,
        filters: IColumnFilters
    ): Observable<PaginatedSearchResults<T>> {
        return this.http
            .post<Paginated<T>>(this.RecordTypeUrl[type], getFiltersPayload(filters), {
                params: getHttpParams(pagination, sort)
            })
            .pipe(
                map(
                    response =>
                        ({
                            ...response,
                            items: response.items.map(item => new ExtendedCase(item)),
                            eventsType: type,
                            limitExceeded: false
                        } as PaginatedSearchResults<T>)
                )
            );
    }

    /**
     * @deprecated The function should be removed after disabling feature flag
     */
    public getCases(params: CaseAdvancedSearchPageParams): Observable<PaginatedSearchResults<ExtendedCase>> {
        return this.getAdvancedSearchData<CaseAdvancedSearchPageParams, IExtendedCaseDTO>(
            params,
            casesAdvancedSearchBaseUrl,
            TableType.Cases
        ).pipe(map(response => ({ ...response, items: response.items.map(item => new ExtendedCase(item)) })));
    }

    public exportCases(params: CaseAdvancedSearchPageParams): Observable<Blob> {
        return this.exportAdvancedSearchData(params, casesAdvancedSearchBaseUrl);
    }

    public exportCasesV3(filters: IColumnFilters, sort: Sort, shouldTransformPayload = true): Observable<Blob> {
        return this.exportAdvancedSearchDataV2(
            filters,
            'ucm/api/v3/advanced-search/cases',
            sort,
            shouldTransformPayload
        );
    }

    public getAlerts(params: AlertAdvancedSearchPageParams): Observable<PaginatedSearchResults<BaseAlert>> {
        return this.getAdvancedSearchData<AlertAdvancedSearchPageParams, IBaseAlertDTO>(
            params,
            alertsAdvancedSearchBaseUrl,
            TableType.Alerts
        ).pipe(map(response => ({ ...response, items: response.items.map(item => new BaseAlert(item)) })));
    }

    public exportAlerts(params: AlertAdvancedSearchPageParams): Observable<Blob> {
        return this.exportAdvancedSearchData(params, alertsAdvancedSearchBaseUrl);
    }

    public exportAlertsV2(filters: IColumnFilters, sort: Sort): Observable<Blob> {
        return this.exportAdvancedSearchDataV2(filters, 'ucm/api/v2/advanced-search/alerts', sort);
    }

    public getApplications(
        params: ApplicationAdvancedSearchPageParams
    ): Observable<PaginatedSearchResults<Application>> {
        return this.getAdvancedSearchData<ApplicationAdvancedSearchPageParams, Application>(
            params,
            applicationAdvancedSearchBaseUrl,
            TableType.Applications
        ).pipe(
            map(paginatedResults => {
                paginatedResults.eventsStatus = params.status;
                return paginatedResults;
            })
        );
    }

    public exportApplications(params: ApplicationAdvancedSearchPageParams): Observable<Blob> {
        return this.exportAdvancedSearchData(params, applicationAdvancedSearchBaseUrl);
    }

    public exportApplicationsV2(filters: IColumnFilters, sort: Sort): Observable<Blob> {
        return this.exportAdvancedSearchDataV2(filters, 'onboarding/api/v2/advanced-search/applications', sort);
    }

    public initFiltersPanel(matExpansionPanel: MatExpansionPanel): void {
        this.filtersPanel = matExpansionPanel;
    }

    public clearFiltersPanel(): void {
        this.filtersPanel = null;
    }

    public changeFiltersPanelOpenState(state: 'open' | 'close'): void {
        if (isNullOrUndefined(this.filtersPanel)) {
            return;
        }

        return this.filtersPanel[state]();
    }

    public onSearchCriteriaClick(key: string): void {
        if (this.onSearchCriteriaClicked || isNullOrUndefined(this.filtersPanel)) {
            return;
        }

        if (this.filtersPanel.expanded) {
            this._onSearchCriteriaClick$.next(key);
            return;
        }

        this.onSearchCriteriaClicked = true;
        this.filtersPanel.afterExpand.pipe(first()).subscribe({
            complete: () => {
                this._onSearchCriteriaClick$.next(key);
                this.onSearchCriteriaClicked = false;
            }
        });
        this.filtersPanel.open();
    }

    public getAlertIDsByTransaction(transactionId: string): Observable<string[]> {
        return this.http.get<string[]>(`${baseUrlV2}/search-alerts/${transactionId}`);
    }

    private getAdvancedSearchData<T extends AdvancedSearchPagedParams, R>(
        params: T,
        requestUrl: string,
        type: TableType
    ): Observable<PaginatedSearchResults<R>> {
        const urlParams = this.getPaginationAndSortURLParams(params.pagination, params.sort);
        const body = this.formatRequestBodyFromURLParams(params);

        return this.http
            .post<Paginated<R>>(requestUrl, body, { params: urlParams })
            .pipe(
                map(
                    response =>
                        ({
                            ...response,
                            items: response.items,
                            eventsType: type,
                            limitExceeded: false
                        } as PaginatedSearchResults<R>)
                )
            );
    }

    private exportAdvancedSearchData<T extends AdvancedSearchPagedParams>(
        params: T,
        baseUrl: string,
        selectedColumns?: string[]
    ): Observable<Blob> {
        const body = this.formatRequestBodyFromURLParams(params);
        const requestUrl = `${baseUrl}/export`;

        if (selectedColumns) {
            body.selectedColumns = selectedColumns;
        }

        body.isAdditionalInfoEnabled = true;

        return this.http.post(requestUrl, body, { responseType: 'blob' });
    }

    private exportAdvancedSearchDataV2(
        filters: IColumnFilters,
        baseUrl: string,
        sort: Sort | SortModelItem,
        shouldTransformPayload = true
    ): Observable<Blob> {
        const body = shouldTransformPayload ? getFiltersPayload(filters) : filters;
        let params = new HttpParams().appendAll(tzHttpParam.fromObject);

        if (sort) {
            params = params.append(
                'sort',
                `${(sort as Sort).active || (sort as SortModelItem).colId},${(sort as Sort).direction ||
                    (sort as SortModelItem).sort}`
            );
        }

        body.isAdditionalInfoEnabled = true;

        return this.http.post(`${baseUrl}/export`, body, { params, responseType: 'blob' });
    }

    private formatRequestBodyFromURLParams(params: AdvancedSearchPagedParams): Partial<AdvancedSearchPagedParams> {
        const body: Partial<AdvancedSearchPagedParams> = { ...params };

        if (params['symbolFirst']) {
            body['symbol'] = this.formatSymbol(params['symbolFirst'], params['symbolSecond']);
        }

        delete body.pagination;
        delete body.sort;
        delete body['symbolFirst'];
        delete body['symbolSecond'];

        for (const paramKey in body) {
            if (isNullOrUndefined(body[paramKey]) || body[paramKey]?.length === 0) {
                delete body[paramKey];
            }
        }

        return body;
    }

    private getPaginationAndSortURLParams(pagination: PaginationFilter, sort?: SortFilter): HttpParams {
        let urlParams = new HttpParams().appendAll({ page: pagination?.pageNumber, size: pagination?.pageSize });

        if (sort) {
            switch (sort.sortBy) {
                case 'localCurrencyAmount':
                    urlParams = urlParams.set('sort', `localNotional,${sort.sortOrder}`);
                    break;
                default:
                    urlParams = urlParams.set('sort', `${sort.sortBy},${sort.sortOrder}`);
                    break;
            }
        }

        return urlParams;
    }

    private formatSymbol(symbolFirst: string, symbolSecond?: string): string {
        let symbolText = '';

        if (symbolFirst) {
            symbolText = symbolFirst;

            if (symbolSecond) {
                symbolText += `/${symbolSecond}`;
            }
        }

        return symbolText;
    }
}
