import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/_services/users/user.service';
import { WindowOpenService } from 'src/app/_services/window-open/window-open.service';
import { ZendeskHelpCenterService } from 'src/app/_services/zendesk/zendesk-help-center/zendesk-help-center.service';
import { ZEWidgetService } from 'src/app/_services/zendesk/zendesk-widget/zendesk-widget.service';
import { NotificationType } from 'src/app/_shared/notification-snackbar/notification-snackbar.component';
import { openSnackbar } from 'src/app/_shared/shared.functions';

@Component({
    selector: 'trds-help-menu',
    templateUrl: './help-menu.component.html',
    styleUrls: ['./help-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpMenuComponent implements OnDestroy {
    private readonly destroy$ = new Subject<void>();
    public zendeskOpen = false;
    private pendingHalpCenter = false;

    constructor(
        private zEWidgetService: ZEWidgetService,
        private zEHelpCenterService: ZendeskHelpCenterService,
        private userService: UserService,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private windowOpenService: WindowOpenService
    ) {
        zEWidgetService.onClose$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.zendeskOpen = false;
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openZendesk(): void {
        this.zendeskOpen = true;
        this.zEWidgetService
            .open()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                error: () => {
                    openSnackbar(
                        this.snackBar,
                        'There was a problem opening the support widget.',
                        NotificationType.ERROR
                    );
                }
            });
    }

    closeZendesk(): void {
        this.zendeskOpen = false;
        this.zEWidgetService.close();
    }

    public openHalpCenter(): void {
        if (this.pendingHalpCenter) {
            return;
        }

        this.pendingHalpCenter = true;
        this.zEHelpCenterService
            .getJWTToken(this.userService.user.name)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                zendeskTokenObj => {
                    this.pendingHalpCenter = false;
                    this.windowOpenService.open(
                        `https:///soliduslabssupport.zendesk.com/access/jwt?jwt=${zendeskTokenObj.token}`
                    );
                },
                () => {
                    this.pendingHalpCenter = false;

                    openSnackbar(
                        this.snackBar,
                        'There was a problem opening solidus halp center.',
                        NotificationType.ERROR
                    );
                }
            );
    }
}
