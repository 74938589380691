import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, Observable, BehaviorSubject, throwError } from 'rxjs';
import { tap, map, switchMap, first, filter, share } from 'rxjs/operators';

import { User, DetailedUser, Team, UserSettings, UserSettingsKeys } from '../../_shared/shared.models';
import { CachedObject } from '../../_shared/cached/cached.class';
import { AuthService } from 'src/app/auth/auth.service';
import { SourceDataCache } from '../../_shared/cached/source-data-cache/source-data-cache.class';
import { isEqual } from 'lodash';
import { isNullOrUndefined } from '../../_shared/shared.functions';
import { Auth } from '../../auth/auth.model';

export const baseUrl = 'auth/api/v1/users'; // microserviceKey + pathPrefix
export const baseUrlV2 = 'auth/api/v2/users'; // microserviceKey + pathPrefix
export const basePolicyManagerUrl = 'policy-manager/api/v1'; // microService + pathPrefix

type UserMeResponse = {
    user: DetailedUser;
    data: string;
    [UserFlag.MakerChecker]: boolean;
    [UserFlag.STRReport]: boolean;
    [UserFlag.DexTradeAdvancedSearch]: boolean;
    [UserEnv.BaseCurrency]: string;
    solidusClientId: string;
    settings?: {
        ftxColumnsEnabled?: boolean;
    };
};
type UserSettingsServerSettings = { settings: { [key in string]: string } };

export enum UserFlag {
    MakerChecker = 'makerCheckerEnabled',
    STRReport = 'strReportEnabled',
    DexTradeAdvancedSearch = 'dexTradeViewEnabled'
}

export enum UserEnv {
    BaseCurrency = 'baseCurrency'
}

export type TenantConfig = {
    [key: string]: boolean | string;
};

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly _teams: CachedObject<Team[]>;
    private readonly _userCache: SourceDataCache<DetailedUser>;
    private readonly _userSettingsCache: SourceDataCache<UserSettings>;

    private readonly _solidusClientId$ = new BehaviorSubject<string | null>(null);

    private userFlags: { [key in UserFlag]: boolean } = {
        [UserFlag.MakerChecker]: false,
        [UserFlag.STRReport]: false,
        [UserFlag.DexTradeAdvancedSearch]: false
    };

    private userEnvs: { [key in UserEnv]: string } = {
        [UserEnv.BaseCurrency]: 'USD'
    };

    private readonly getActiveMembers$ = this.http.get<Team[]>(`${baseUrl}/teams/active-members-only`).pipe(
        share(),
        tap(teams => {
            this._teams.source = teams;
        })
    );

    constructor(private http: HttpClient, private authService: AuthService) {
        this._teams = new CachedObject();
        this._userCache = new SourceDataCache<DetailedUser>(this.getUserFromServer());
        this._userSettingsCache = new SourceDataCache<UserSettings>(this.getUserSettingsFromServer());

        this.authService.userFlagUpdate$.subscribe(({ type, value }) => {
            this.userFlags[type] = value;
        });

        this.authService.userEnvUpdate$.subscribe(({ type, value }) => {
            this.userEnvs[type] = value;
        });
    }

    get id(): number | null {
        return this.user?.id || null;
    }

    get user$(): Observable<DetailedUser | null> {
        return this._userCache.dataStream;
    }

    getUserFlagStatus(userFlag: UserFlag): boolean {
        return this.userFlags[userFlag];
    }

    getUserEnvStatus(userEnv: UserEnv): string {
        return this.userEnvs[userEnv];
    }

    getUserSettingsField$(field: UserSettingsKeys): Observable<any> {
        return this._userSettingsCache.dataStream.pipe(map(data => (data && data[field] ? data[field] : null)));
    }

    get solidusClientId$(): Observable<string | null> {
        return this._solidusClientId$.asObservable();
    }

    get user(): DetailedUser | null {
        return this._userCache.dataSnapshot;
    }

    /**
     * Get the current user's teams
     */
    get teams(): Observable<Team[]> {
        if (this._teams && this._teams.isFresh) {
            return of(this._teams.source);
        }

        return this.getActiveMembers$;
    }

    /**
     * Get a unique list of members in all the user's teams
     */
    get teamMembers(): Observable<User[]> {
        return this.teams.pipe(
            map<Team[], User[]>(teams => {
                const users: User[] = []; // the list of unique users to return
                const userIds: number[] = []; // keep a quick reference to added users
                const availableTeams: Team[] = this.getAvailableTeams(teams); // Filter available teams
                // get the members of each team
                availableTeams
                    .map(t => t.members)
                    // for each member
                    .forEach(members =>
                        members.forEach(member => {
                            // add to final list only if not already listed
                            if (userIds.indexOf(member.id) === -1) {
                                users.push(member);
                                userIds.push(member.id);
                            }
                        })
                    );
                return users;
            })
        );
    }

    public setUser(user: DetailedUser): void {
        this._userCache.set(user);
    }

    public setUserSettings(settings: UserSettings): void {
        this._userSettingsCache.set(settings);
    }

    public reloadTeams(): void {
        this._teams.stale();
    }

    public setSolidusClientId(id: string): void {
        this._solidusClientId$.next(id);
    }

    public hasPermission(permission: string): boolean {
        return !!this.user && this.user.permissions.indexOf(permission) > -1;
    }

    public hasPermission$(permission: string): Observable<boolean> {
        return this.user$.pipe(map(() => this.hasPermission(permission)));
    }

    public hasSomePermissions$(permissions: string[]): Observable<boolean> {
        return this.user$.pipe(
            map<DetailedUser, boolean>(() => permissions.some(p => this.hasPermission(p)))
        );
    }

    public permissions$(): Observable<string[] | null> {
        return this.user$.pipe(map(user => (user ? user.permissions : null)));
    }

    public initUser(): Promise<User | null> {
        if (this.authService.isLoggedIn()) {
            this._userCache.update();
            this.initUserSettings();

            return this.user$
                .pipe(
                    filter(user => !isNullOrUndefined(user)),
                    first()
                )
                .toPromise();
        }

        return Promise.resolve(null);
    }

    public initUserSettings(): void {
        this._userSettingsCache.update();
    }

    public updateSettings(key: UserSettingsKeys, settings: any): Observable<UserSettings> {
        return this._userSettingsCache.dataStream.pipe(
            first(),
            switchMap(baseData => {
                if (baseData && isEqual(settings, baseData[key])) {
                    return of(void 0);
                }

                return this.http
                    .patch<UserSettingsServerSettings>(`${baseUrl}/settings/${key}`, {
                        settings: JSON.stringify(settings)
                    })
                    .pipe(
                        map(data => this.parseServerUserSettings(data)),
                        tap(settings => this.setUserSettings(settings))
                    );
            })
        );
    }

    private getAvailableTeams(teams: Team[]): Team[] {
        const teamIds = this.user?.teamIds ?? [];
        let availableTeams: Team[] = [];

        teamIds.forEach(id => {
            const userTeam = teams.filter(t => t.id === id);
            availableTeams = availableTeams.concat(userTeam);
        });

        return availableTeams;
    }

    private getUserFromServer(): Observable<DetailedUser> {
        return this.http.get<UserMeResponse>(`${baseUrlV2}/me`).pipe(
            tap(data => this.setUserFlagsAndEnvs(data)),
            tap(response => this.setSolidusClientId(response.data)),
            map(response => {
                const detailedUser: DetailedUser = { ...response.user };
                detailedUser.solidusClientId = response.solidusClientId;
                return detailedUser;
            })
        );
    }

    private setUserFlagsAndEnvs(data: Auth) {
        for (const userFlag of Object.values(UserFlag)) {
            this.userFlags[userFlag] = !!data[userFlag];
        }
        for (const userEnv of Object.values(UserEnv)) {
            if (!isNullOrUndefined(data[userEnv])) {
                this.userEnvs[userEnv] = data[userEnv];
            }
        }
    }

    private getUserSettingsFromServer(): Observable<UserSettings> {
        return this.http
            .get<UserSettingsServerSettings>(`${baseUrl}/settings`)
            .pipe(map(data => this.parseServerUserSettings(data)));
    }

    private parseServerUserSettings(data: UserSettingsServerSettings): UserSettings {
        if (!data?.settings) {
            return {} as UserSettings;
        }

        return Object.keys(data.settings).reduce((acc, key) => {
            try {
                acc[key] = JSON.parse(data.settings[key]);
            } catch (e) {
                return acc;
            }
            return acc;
        }, {} as UserSettings);
    }

    public getConfigByTenantId(): Observable<TenantConfig> {
        if (!this.user?.solidusClientId) {
            return throwError('solidusClientId is undefined');
        }

        return this.http.get<TenantConfig>(`${basePolicyManagerUrl}/tenant-config/${this.user.solidusClientId}`);
    }
}
