import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LaunchdarklyFeatureFlagService } from 'src/app/_services/launchdarkly-feature-flag/launchdarkly-feature-flag.service';

import { Features } from 'src/app/app-config.service';

import { UserService } from '../users/user.service';

// TODO: Add additional modules as they are built

// Insertion order defines default module
// Make sure to keep the modules in order of importance for default loading
const MODULES = {
    ucm: [
        'CASE_CREATE',
        'CASE_UPDATE',
        'CASE_MY_OPEN_VIEW',
        'CASE_ALL_OPEN_VIEW',
        'CASE_VIEW_TEAM_RESOLVED',
        'CASE_VIEW_MY_RESOLVED',
        'CASE_VIEW_ALL_RESOLVED',
        'CASE_TEAM_OPEN_VIEW',
        'CASE_VIEW_TEAM_RESOLVED',
        'CASE_RESOLVE',
        'CASE_LINK',
        'CASE_REOPEN',
        'CASE_ASSIGN',
        'CASE_REMOVE_ATTACHMENT',
        'CASE_INVESTIGATE',
        'CASE_UNLINK_ANY'
    ],
    'market-inspection': ['MARKET_INSPECTION'],
    'market-inspection-v2': ['MARKET_INSPECTION'],
    'model-testing': ['MODEL_TESTING'],
    onboarding: [
        'APPLICATION_ASSIGN',
        'APPLICATION_APPROVE',
        'APPLICATION_REJECT',
        'APPLICATION_UPDATE',
        'APPLICATION_ALL_OPEN_VIEW',
        'APPLICATION_ALL_RESOLVED_VIEW'
    ],
    algos: ['ALGO_THRESHOLDS_VIEW', 'ALGO_THRESHOLDS_MODIFY'],
    admin: ['ADMIN'],
    ucv: ['SCV_UPDATE'],
    'system-info': ['ADMIN'],
    'advanced-search': [
        'CASE_MY_OPEN_VIEW',
        'CASE_VIEW_MY_RESOLVED',
        'CASE_TEAM_OPEN_VIEW',
        'CASE_VIEW_TEAM_RESOLVED',
        'CASE_ALL_OPEN_VIEW',
        'CASE_VIEW_ALL_RESOLVED'
    ],
    'risk-model': ['ADMIN', 'APPLICATION_APPROVE', 'ALGO_THRESHOLDS_MODIFY'],
    policy: ['POLICY_MANAGER'],
    'notification-settings': []
};
@Injectable({
    providedIn: 'root'
})
export class VisibilitiesService {
    private readonly modules: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set<string>());

    private readonly hiddenOnDemoSandboxEnv = ['market-inspection-v2', 'model-testing', 'onboarding'];

    constructor(private userService: UserService, private launchDarklyService: LaunchdarklyFeatureFlagService) {
        this.userService.permissions$().subscribe(permissions => {
            if (permissions) {
                this.setModules(permissions);
            } else {
                this.modules.next(new Set());
            }
        });
    }

    private setModules(permissions: string[]): void {
        const moduleNames = Object.keys(MODULES);
        const modules = new Set<string>();

        moduleNames.forEach((name, index) => {
            const hasPermission = MODULES[name].some(perm => permissions.includes(perm));

            // Add current module name to Set if user has one or more corresponding permissions OR if module does not require any permissions (can be visible for all users)
            if (hasPermission || !MODULES[name].length) {
                modules.add(name);
            }
        });

        this.modules.next(modules);
    }

    public isVisible$(path: string): Observable<boolean> {
        if (this.shouldHideOnDemoSandboxEnv(path)) {
            return of(false);
        }

        return this.modules.pipe(
            mergeMap(modules => {
                const isVisible = modules.has(path);

                if (path === 'model-testing') {
                    return this.launchDarklyService.flagChange.pipe(
                        map(flagStore => isVisible && flagStore[Features.MODEL_TESTING_NEW])
                    );
                }

                return of(isVisible);
            })
        );
    }

    public defaultModule$(): Observable<string | null> {
        return this.modules.pipe(
            map(modules => {
                if (modules.size) {
                    return modules.values().next().value;
                }

                return null;
            })
        );
    }

    private shouldHideOnDemoSandboxEnv(path: string): boolean {
        return this.hiddenOnDemoSandboxEnv.includes(path) && this.isDemoSandboxEnv();
    }

    private isDemoSandboxEnv(): boolean {
        return ['SANDBOX_CLIENT'].includes(this.userService.user?.solidusClientId);
    }
}

// NOTE: Full list of permissions:
//
// 'CASE_CREATE';
// 'CASE_UPDATE';
// 'REPORT_VIEW';
// 'REPORT_CREATE';
// 'REPORT_RUN';
// 'REPORT_DELETE';
// 'TEST_VIEW';
// 'TEST_RUN';
// 'REQUEST_VIEW';
// 'REQUEST_UPDATE';
// 'REQUEST_APPROVE';
// 'REQUEST_REJECT';
// 'DATA_FILE_UPLOAD';
// 'CASE_MY_OPEN_VIEW';
// 'CASE_ALL_OPEN_VIEW';
// 'CASE_VIEW_TEAM_RESOLVED';
// 'CASE_RESOLVE';
// 'CASE_LINK';
// 'CASE_REOPEN';
// 'CASE_ASSIGN';
// 'CASE_REMOVE_ATTACHMENT';
// 'INTERNAL_ACCESS';
// 'CASE_INVESTIGATE';
// 'CASE_UNLINK_ANY';
// 'MARKET_INSPECTION';
// 'MODEL_TESTING_REVIEW';
// 'MODEL_TESTING_SUBMIT';
// 'MODEL_TESTING_PROD';
// 'SUBMIT_TRANSACTIONS';
// 'CALLBACK_DEST_VIEW';
// 'CALLBACK_DEST_MODIFY';
// 'SCV_UPDATE';
// 'SUBMIT_ORDERS_EXECUTIONS'
// 'MODEL_TESTING'
