import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    public isLoading$ = new Subject<boolean>();

    // don't show the application request spinner
    public blockLoading = false;

    loadingMap: Map<string, boolean> = new Map<string, boolean>();

    setLoading(loading: boolean, url: string): void {
        if (!url) {
            throw new Error('The request URL must be provided to the LoadingService.setLoading function');
        }
        if (loading) {
            this.loadingMap.set(url, loading);
        } else if (this.loadingMap.has(url)) {
            this.loadingMap.delete(url);
        }

        const isLoading = !this.blockLoading && this.loadingMap.size > 0;
        setTimeout(() => this.isLoading$.next(isLoading));
    }
}
