<button
    *ngIf="flat"
    class="spinner-prefix"
    role="button"
    mat-flat-button
    color="{{ color }}"
    (click)="click()"
    [disabled]="disabled || pending"
>
    <div class="btn-content" [class.hidden]="pending">
        <mat-icon
            class="button-icon button-icon-before"
            *ngIf="icon && iconBeforeText"
            fontSet="material-icons-outlined"
        >
            {{ icon }}
        </mat-icon>
        <span class="text">{{ text }}</span>
        <mat-icon class="button-icon" *ngIf="icon && !iconBeforeText" fontSet="material-icons-outlined">
            {{ icon }}
        </mat-icon>
    </div>
    <span *ngIf="pending" class="spinner-container">
        <mat-spinner [diameter]="20" class="trds-spinner"></mat-spinner>
    </span>
</button>

<button
    *ngIf="stroked"
    class="spinner-prefix"
    role="button"
    mat-stroked-button
    color="{{ color }}"
    (click)="click()"
    [disabled]="disabled || pending"
>
    <span *ngIf="!(icon && iconBeforeText)" [class.hidden]="pending">{{ text }}</span>
    <div *ngIf="icon && iconBeforeText" class="btn-content" [class.hidden]="pending">
        <mat-icon class="button-icon button-icon-before" fontSet="material-icons-outlined">
            {{ icon }}
        </mat-icon>
        <span class="text">{{ text }}</span>
        <mat-icon class="button-icon" *ngIf="icon && !iconBeforeText" fontSet="material-icons-outlined">
            {{ icon }}
        </mat-icon>
    </div>
    <span *ngIf="pending" class="spinner-container">
        <mat-spinner [diameter]="20" class="trds-spinner"></mat-spinner>
    </span>
</button>

<button
    *ngIf="raised"
    class="spinner-prefix"
    role="button"
    mat-raised-button
    color="{{ color }}"
    (click)="click()"
    [disabled]="disabled || pending"
>
    <span [class.hidden]="pending">{{ text }}</span>
    <span *ngIf="pending" class="spinner-container">
        <mat-spinner [diameter]="20" class="trds-spinner"></mat-spinner>
    </span>
</button>

<button
    *ngIf="iconBtn"
    class="spinner-prefix"
    role="button"
    mat-icon-button
    color="{{ color }}"
    (click)="click()"
    [disabled]="disabled || pending"
>
    <div class="btn-content" [class.hidden]="pending">
        <mat-icon class="button-icon" fontSet="material-icons-outlined">
            {{ icon }}
        </mat-icon>
    </div>
    <span *ngIf="pending" class="spinner-container">
        <mat-spinner [diameter]="20" class="trds-spinner"></mat-spinner>
    </span>
</button>

<button
    *ngIf="base"
    mat-button
    class="spinner-prefix"
    role="button"
    color="{{ color }}"
    (click)="click()"
    [disabled]="disabled || pending"
>
    <div class="btn-content" [class.hidden]="pending">
        <span class="text">{{ text }}</span>
        <mat-icon class="button-icon" *ngIf="icon" fontSet="material-icons-outlined">
            {{ icon }}
        </mat-icon>
    </div>

    <span *ngIf="pending" class="spinner-container">
        <mat-spinner [diameter]="20" class="trds-spinner"></mat-spinner>
    </span>
</button>
