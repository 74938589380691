import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-enterprise';

import { DateRange } from '@angular/material/datepicker';

import { TrdsDateFilterModel } from 'src/app/ag-grid-tables/shared/models.shared';
import { DateRangePickerHeaderComponent } from 'src/app/_shared/date-range-picker-header/date-range-picker-header.component';
import { isAnyNullOrUndefined } from 'src/app/_shared/shared.functions';
import { DefaultPresets } from 'src/app/tables-with-filters/shared.models';

import moment, { Moment } from 'moment';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'trds-ag-date-filter',
    templateUrl: './ag-date-filter.component.html',
    styleUrls: ['./ag-date-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgDateFilterComponent implements IFilterAngularComp {
    public readonly DatePickerHeaderComponent = DateRangePickerHeaderComponent;
    public readonly panelClasses = ['table-with-filters-date-picker', 'no-template-header'];
    public readonly maxDate = moment.utc();

    public from: Moment;
    public to: Moment;

    private params: IFilterParams;

    agInit(params: IFilterParams): void {
        this.params = params;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return false;
    }

    getModel(): TrdsDateFilterModel | null {
        if (isAnyNullOrUndefined(this.from, this.to)) {
            return null;
        }

        return {
            filterType: 'trdsDate',
            from: this.from.valueOf(),
            to: this.to.valueOf()
        };
    }

    isFilterActive(): boolean {
        return this.from?.isValid() && this.to?.isValid();
    }

    setModel(model: TrdsDateFilterModel): void {
        this.from = model?.from ? moment.utc(model.from) : null;
        this.to = model?.to ? moment.utc(model.to) : null;
    }

    public datePickerOpened(): void {
        this.DatePickerHeaderComponent.datePresets = DefaultPresets;
    }

    public datePickerChange(range: DateRange<Moment>): void {
        if (!range.start?.isValid() || !range.end?.isValid()) {
            return;
        }

        const dateTimeRange = this.setTimeForDateRange(range);
        if (
            this.from?.valueOf() === dateTimeRange.start?.valueOf() &&
            this.to?.valueOf() === dateTimeRange.end?.valueOf()
        ) {
            return;
        }

        this.updateFilter(dateTimeRange.start, dateTimeRange.end);
    }

    private updateFilter(from: Moment | null, to: Moment | null): void {
        this.from = from;
        this.to = to;
        this.params.filterChangedCallback();
    }

    private setTimeForDateRange(range: DateRange<Moment>): DateRange<Moment> {
        const start = cloneDeep(range.start);
        const end = cloneDeep(range.end);
        return new DateRange<Moment>(start.startOf('day'), end.endOf('day'));
    }
}
