<div class="container">
    <mat-form-field appearance="fill" color="accent">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input #dateRangeInput [rangePicker]="picker" [max]="maxDate">
            <input
                matStartDate
                placeholder="mm/dd/yyyy"
                (keydown.enter)="datePickerChange(dateRangeInput.value)"
                [value]="from"
                auto_id="txt_FilterFromDate"
            />
            <input
                matEndDate
                placeholder="mm/dd/yyyy"
                (keydown.enter)="datePickerChange(dateRangeInput.value)"
                [value]="to"
                auto_id="txt_FilterToDate"
            />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker
            appearance="fill"
            color="accent"
            [panelClass]="panelClasses"
            #picker
            [calendarHeaderComponent]="DatePickerHeaderComponent"
            (opened)="datePickerOpened()"
            (closed)="datePickerChange(dateRangeInput.value)"
        ></mat-date-range-picker>
    </mat-form-field>
</div>
