import { Component, OnInit, OnDestroy, HostBinding, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MatIconRegistry } from '@angular/material/icon';

import { UserService } from 'src/app/_services/users/user.service';
import { User } from 'src/app/_shared/shared.models';
import { TitleService, PageTitle } from 'src/app/_services/title/title.service';
import { ReloadService } from 'src/app/_services/reload/reload.service';
import { PendoService } from 'src/app/_services/pendo/pendo.service';
import { AuthService } from 'src/app/auth/auth.service';

import { AppConfigService, Features } from 'src/app/app-config.service';
import { LaunchdarklyFeatureFlagService } from 'src/app/_services/launchdarkly-feature-flag/launchdarkly-feature-flag.service';

@Component({
    selector: 'trds-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class') class = 'trds-breadcrumbs';

    public user: User;

    public link: string;
    public pages: PageTitle[] = [];
    public subPages: PageTitle[] = [];
    public mainPage: PageTitle;

    private destroy$ = new Subject<void>();

    public zendeskEnabled: boolean;
    public notificationSettingsEnabled: boolean;
    public autoIdTitle = 'text_PageTitle';

    @ViewChild('titleRef') public titleRef: ElementRef;

    constructor(
        private router: Router,
        private userService: UserService,
        private auth: AuthService,
        private titleService: TitleService,
        private title: Title,
        private iconReg: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private reloadService: ReloadService,
        private pendoService: PendoService,
        private appConfigService: AppConfigService,
        private launchdarklyFeatureFlagService: LaunchdarklyFeatureFlagService
    ) {
        const logo = this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/nav-icons/logo.svg');
        this.iconReg.addSvgIcon('logo', logo);
        this.launchdarklyFeatureFlagService.flagChange.pipe(takeUntil(this.destroy$)).subscribe(flags => {
            this.zendeskEnabled = flags[Features.ZENDESK] ? flags[Features.ZENDESK] : false;
            this.notificationSettingsEnabled = !!flags[Features.PERSONAL_SETTING_EMAIL_NOTIFICATIONS];
        });
    }

    ngOnInit(): void {
        this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe(u => (this.user = u));
        this.titleService.title.pipe(takeUntil(this.destroy$)).subscribe(pages => {
            this.pages = pages;

            this.subPages = pages.length > 1 ? pages.slice(1, pages.length - 1) : [];
            this.mainPage = pages.length > 0 ? pages[pages.length - 1] : new PageTitle('Solidus', []);
            this.autoIdTitle = this.mainPage.autoIdAttrName;

            // Angular title service
            if (pages.length > 0) {
                this.title.setTitle(pages[pages.length - 1].title);
            }
        });
    }

    ngAfterViewInit(): void {
        this.titleService.titleFocus$
            .pipe(
                filter(shouldFocus => shouldFocus),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.titleRef?.nativeElement?.focus();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public routeTo(page: PageTitle): void {
        this.router.navigate(page.route);
    }

    public routeToParent(): void {
        // this.pages.length - 1 -- index of current page
        // this.pages.length - 2 -- index of parent page
        const parentPage = this.pages[this.pages.length - 2];
        this.routeTo(parentPage);
    }

    public logout(): void {
        this.auth.logout();
        this.pendoService.clear();
        this.router.navigateByUrl('/');
    }

    public goToNotificationSettingsPage(): void {
        this.router.navigateByUrl('/notification-settings');
    }

    public focusOnNavBar(ev: KeyboardEvent): void {
        if (ev.key === 'ArrowLeft') {
            this.titleService.focusOnTab();
        }
    }
}
