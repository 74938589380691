import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from 'ag-grid-enterprise';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));

LicenseManager.setLicenseKey(
    `Using_this_AG_Grid_Enterprise_key_( AG-049475 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Solidus Labs )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Halo )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Halo )_need_to_be_licensed___( Halo )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 27 December 2024 )____[v2]_MTczNTI1NzYwMDAwMA==7f61097247f997718cc9ac1a87239af2`
);
