import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { MatSidenavContent } from '@angular/material/sidenav';

import { AppConfigService, Features } from '../app-config.service';
import { TitleService } from '../_services/title/title.service';

@Component({
    selector: 'trds-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    @ViewChild(MatSidenavContent, { static: true }) private sidenavContent: MatSidenavContent;

    public zendeskEnabled: boolean;

    @HostListener('window:keydown', ['$event'])
    keyEvent(event: KeyboardEvent): void {
        if (event.altKey) {
            if (event.key === 'ArrowLeft') {
                this.titleService.focusOnTab();
            }
        }
    }

    constructor(
        private router: Router,
        private appConfigService: AppConfigService,
        private titleService: TitleService
    ) {
        this.zendeskEnabled = !this.appConfigService.isFeatureDisabled(Features.ZENDESK);
    }

    ngOnInit(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.sidenavContent && this.sidenavContent.scrollTo({ top: 0 }));
    }
}
