export type EventTypeName = 'msPrivateOrder' | 'msPrivateExecution' | 'tmChainalysisEvent';
export type IMSEventTypeDTO = IOrderEventDTO | IExecutionEventDTO;

export enum EventStatus {
    UNKNOWN = 0, // explicit as fallback
    NEW,
    PENDING_NEW,
    CANCELLED,
    CANCELED,
    PARTIALLY_FILLED,
    FILLED,
    PENDING_CANCEL,
    PARTIALLY_CANCELED,
    ACCEPTED,
    AMEND,
    PENDING_REPLACE,
    REPLACED,
    STOPPED,
    REJECTED,
    EXPIRED
}

export enum EventSide {
    UNKNOWN = 0, // explicit as fallback
    BUY,
    SELL
}

export enum SecurityType {
    Spot = 'SPOT',
    Fut = 'FUT',
    Opt = 'OPT',
    Swap = 'SWAP'
}

export enum PositionEffect {
    Open = 'OPEN',
    Close = 'CLOSE'
}

export enum PutCall {
    Put = 'PUT',
    Call = 'CALL'
}

export enum ExerciseStyle {
    American = 'AMERICAN',
    European = 'EUROPEAN',
    Asian = 'ASIAN',
    Bermuda = 'BERMUDA'
}

export interface IBaseEventDTO {
    id: string;
    version: number;

    clientId: string;
    actorId: string;

    exVenue: string;
    quantity: string;
    usdNotional: number;
    localCurrency: string;
    localNotional: number;

    ipAddress: string;
    matchingIpAddress: string;
}

export interface ITMEventDTO extends IBaseEventDTO {
    currency: string;
    eventType: string;
    externalAccountFunction: string;
    externalAccountId: string;
    externalAccountType: string;
    internalAccountId: string;
    status: string;
    timestamp: number;
    transactionHash: string;
    transactionType: string;
    jurisdiction: string;
    location: string;
    clientProfile: string;
    legalEntity: string;
    kytId: string;
    blockchain: string;
    entityIdentifier: string;
    algorithmInfo: {
        configuredAlgorithms: number;
        failedAlgorithmNames: string[];
        failedAlgorithms: number;
        passedAlgorithmNames: string[];
        passedAlgorithms: number;
        runAlgorithms: number;
    };
}

export interface IBaseMSEventDTO extends IBaseEventDTO {
    timestamp: number;
    eventType: EventTypeName;

    account: string;

    matchingClientId?: string;
    matchingAccount?: string;
    matchingActorId?: string;
    matchingOrderId?: string;

    status: string;
    side: string;
    symbol: string;
    price: string;

    executedQuantity: string;

    ipAddress: string;
    matchingIpAddress: string;

    cumQty?: string;
    leavesQty?: string;
    tradeType?: string;
    securityType: SecurityType;
    exchangeSymbol: string;
    expirationDateTime: number;
    settleDateTime: number;
    positionEffect: PositionEffect;
    putCall: PutCall;
    strikePrice: string;
    strikeValue: string;
    fundingRate: string;
    contractMultiplier: string;
    cfiCode: string;
    exerciseStyle: ExerciseStyle;
}

export interface IExecutionEventDTO extends IBaseMSEventDTO {
    orderId: string;
    lastLiquidityInd: string;
    executedPrice: string;
    counterParty: string;
    counterPartyType: string;
    ipAddress: string;
    orderCapacity?: string;
    matchingOrderCapacity?: string;
    buIdentifier?: string;

    snapshot: ISnapshotDTO;
}

export interface IOrderEventDTO extends IBaseMSEventDTO {
    avgPrice: string;
    exDestination: string;
    orderType: string;
    priceType: string;
    timeInForce: string;
    capacity: string;
    deskId: string;
    algoName: string;
    origClOrdId: string;
    originationFirm: string;
    originationTrader: string;
    expireDateTime: number;
    originalTransactionTime: number;
    bestBid?: string;
    bestAsk?: string;
    text?: string;
    lastLiquidityInd?: string;
    snapshot: ISnapshotDTO;
    ipAddress: string;
    contingencyType?: string;
    buIdentifier?: string;
    executionPrice?: string;
}

export type IOrderEventDTOv2 = string[];

export type ISnapshotDTO = {
    BID: IOrderBookEntryDTO[];
    ASK: IOrderBookEntryDTO[];
};

export type IOrderBookEntryDTO = {
    tradingPair: string;
    source: string;
    side: string;
    price: string;
    quantity: string;
    internalQuantity: string;
};

export interface IDexTrade {
    timestamp: number;
    transactionHash: string;
    wallet: string;
    sellSymbol: string;
    sellSymbolContract: string;
    sellSymbolQuantity: number;
    buySymbol: string;
    buySymbolContract: string;
    buySymbolQuantity: number;
    sellUsdValue: number;
    buyUsdValue: number;
    dexName: string;
    contractAddress: string;
    gas: string;
}
