export enum LinkContextType {
    CASE = 'CASE',
    ALERT = 'ALERT'
}

export interface BaseLinkContext {
    version: string;
    type: LinkContextType;
}

export interface CaseLinkContext extends BaseLinkContext {
    caseIds: string[];
}

export interface AlertLinkContext extends BaseLinkContext {
    alertIds: string[];
}
