export const environment = {
    production: true,
    protocol: 'https',
    domain: 'staging.soliduslabs.app',
    zendeskKey: 'bb2f514d-904f-4fee-a481-be5fcd33b678',
    microServices: {
        auth: { subDomain: 'auth.', port: '' },
        ucm: { subDomain: 'data-api.', port: '' },
        mi: { subDomain: 'solidus-market-inspection.', port: '' },
        onboarding: { subDomain: 'stark.', port: '' },
        scv: { subDomain: 'client-store.', port: '' },
        stats: { subDomain: 'client-stats.', port: '' },
        thresholds: { subDomain: 'configuration-manager.', port: '' },
        'advanced-search': { subDomain: 'advanced-search.', port: '' },
        'client-activity': { subDomain: 'client-activity.', port: '' },
        'policy-manager': { subDomain: 'policy-manager.', port: '' },
        'market-data': { subDomain: 'market-data-api.', port: '' }
    },
    launchdarkly: {
        development: {
            sdk_key: 'sdk-cfa3d8aa-3e68-40ac-96f5-1e0f34704c34',
            client_side_id: '638ef34992acc111743437ff'
        },
        uat: {
            sdk_key: 'sdk-0f543496-dc3e-4308-b8eb-e9e27ff8443f',
            client_side_id: '63be7ca6114c4812f92c02b1'
        },
        production: {
            sdk_key: 'sdk-1884218a-d661-4d55-a4e3-90f94c7cb8d4',
            client_side_id: '638f3e4d92acc111743a7ae1'
        }
    }
};
