import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { EMPTY, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, mergeMap } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { UserService } from '../_services/users/user.service';
import { environment } from 'src/environments/environment';

export const baseUrl = 'ucm/api/v1/dashboard'; // microserviceKey + pathPrefix

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    private log$ = new Subject<{ path: string; message: string }>();

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private userService: UserService,
        private router: Router
    ) {
        this.log$
            .pipe(
                distinctUntilChanged((p, q) => p.message === q.message && p.path === q.path),
                mergeMap(v =>
                    this.http.post(v.path, { content: v.message }).pipe(
                        catchError((error: HttpErrorResponse) => {
                            console.error(`LoggingService caught an error: ${error.message}`);
                            return EMPTY;
                        })
                    )
                )
            )
            .subscribe();
    }

    private logDistinct(path: string, message: string): void {
        this.log$.next({ path, message: this.appendErrorDetails(message) });
    }

    info(message: string): void {
        this.toServer() ? this.logDistinct(`${baseUrl}/log/info`, message) : console.log(message);
    }

    warn(message: string): void {
        this.toServer() ? this.logDistinct(`${baseUrl}/log/warn`, message) : console.warn(message);
    }

    error(message: string): void {
        this.toServer() ? this.logDistinct(`${baseUrl}/log/error`, message) : console.error(message);
    }

    private toServer(): boolean {
        return environment.production && this.auth.isLoggedIn();
    }

    private appendErrorDetails(message: string): string {
        return JSON.stringify({ userId: this.userService.id, message, appUrl: this.router.url });
    }
}
