<div class="dialog-wrapper cases-notify-dialog" auto_id="popUpWindow">
    <div
        mat-dialog-title
        class="dialog-icon-header"
        [class.warning]="config.type === 'ERROR'"
        auto_id="txt_NotifyPopUpWindow"
    >
        <mat-icon *ngIf="config.type === 'INFO'" fontSet="material-icons-outlined">warning_amber</mat-icon>
        <mat-icon *ngIf="config.type === 'ERROR'" fontSet="material-icons-outlined">error_outline</mat-icon>
        <mat-icon *ngIf="config.type === 'WARNING'" fontSet="material-icons-outlined">warning_amber</mat-icon>
        <h2 tabindex="0">{{ config.title }}</h2>
    </div>
    <div mat-dialog-content>
        <div class="body" tabindex="0" auto_id="text_popUpWindowText">{{ config.body }}</div>
        <ul *ngIf="config.items">
            <li *ngFor="let item of config.items" tabindex="0">- {{ item }}</li>
        </ul>
        <div class="caption" *ngIf="config.caption" tabindex="0">{{ config.caption }}</div>
    </div>
    <div mat-dialog-actions align="end">
        <button
            mat-stroked-button
            *ngIf="config.showCancel"
            [mat-dialog-close]="false"
            color="primary"
            auto_id="btn_Cancel"
        >
            {{ config.cancelButtonText || 'Cancel' }}
        </button>
        <trds-spinner-button
            cdkFocusInitial
            flat
            [text]="config.continueButtonName"
            [pending]="pending"
            (clickEvent)="confirm()"
            class="confirm"
            color="accent"
            auto_id="btn_Confirm"
        ></trds-spinner-button>
    </div>
</div>
<trds-dialog-error [text]="showErrorMessage ? errorMessage : ''"></trds-dialog-error>
