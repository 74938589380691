import { Component, HostBinding, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'trds-dialog-error',
    templateUrl: './dialog-error.component.html',
    styleUrls: ['./dialog-error.component.scss']
})
export class DialogErrorComponent implements OnChanges {
    @HostBinding('class') class = 'trds-dialog-error';
    @Input() text: string;
    @Input() autoIdMsg: string;
    @Input() autoIdClose: string;
    public show: boolean;

    ngOnChanges() {
        this.show = !!this.text; // show only if text has content
    }

    public hide(): void {
        this.show = false;
        this.text = undefined;
    }
}

// Config only using for dialog with dialog-error
export const DialogWidthConfig = {
    SMALL: '24rem',
    MEDIUM: '42rem',
    LARGE: '58rem'
};
