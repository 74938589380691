import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from '../../_logging/logging.service';
import { Observable } from 'rxjs';
import { AlertLinkContext, BaseLinkContext, CaseLinkContext, LinkContextType } from './link-context.models';
import { DefaultSearchType } from '../../advanced-search/advanced-search-service/advanced-search.service';
import { TableType } from '../../advanced-search/advanced-search.models';
import { map } from 'rxjs/operators';

export const baseUrl = 'ucm/api/v1/dashboard/link/';

@Injectable({
    providedIn: 'root'
})
export class LinkContextService {
    private contextMapper: { [key in LinkContextType]: (context: BaseLinkContext) => DefaultSearchType } = {
        [LinkContextType.CASE]: context => ({ [TableType.Cases]: { caseId: (context as CaseLinkContext).caseIds } }),
        [LinkContextType.ALERT]: context => ({
            [TableType.Alerts]: { alertId: (context as AlertLinkContext).alertIds }
        })
    };

    constructor(private http: HttpClient, public log: LoggingService) {}

    public getLinkContextById(contextId: string): Observable<BaseLinkContext> {
        return this.http.get<BaseLinkContext>(baseUrl + contextId);
    }

    public getAdvancedSearchContextById(contextId: string): Observable<DefaultSearchType> {
        return this.getLinkContextById(contextId).pipe(map(context => this.contextMapper[context.type](context)));
    }
}
