import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ArrayPropsParser } from './shared.models';
export const DEFAULT_CANDLES_COLOR = '#ffffff';

export type CandlesListDTO = ICandlesDTO[];
export type CandlesListDTOv2 = ICandlesDTOv2[];

export interface ICandlesDTO {
    exchange: string;
    symbol: string;
    candles: ICandleDTO[];
    colors?: string[];
}

export interface ICandlesDTOv2 {
    exchange: string;
    symbol: string;
    candles: ICandleDTOv2[];
    colors?: string[];
}

export type ICandleDTO = {
    time: number;
    close: string;
    open: string;
    high: string;
    low: string;
    volume: string;
    count: string;
    symbol: string;
    exchange: string;
    real: boolean;
    solidusClientId: string;
    bestBid: string;
    bestAsk: string;
};

export type ICandleDTOv2 = string[];

export class CandlesSet {
    private set: Set<Candles>;
    get length(): number {
        return this.set?.size || 0;
    }
    get candlesList(): IterableIterator<Candles> {
        return this.set.values();
    }

    constructor(dto: CandlesListDTO) {
        this.set = new Set();
        if (dto) {
            dto.forEach(item => {
                this.set.add(new Candles(item));
            });
        }
    }

    /**
     * A copy constructor. Be mindful of memory leaks!
     * @param candlesList A list of Candles
     */
    static fromCandlesList(candlesList: Candles[]): CandlesSet {
        const newThis = new this([]);
        candlesList.forEach(candles => {
            newThis.set.add(candles);
        });

        return newThis;
    }

    /**
     * Compare two candle maps and their contents
     * @param a first map
     * @param b second map
     */
    static same(a: CandlesSet, b: CandlesSet): boolean {
        if (a.set.size !== b.set.size) {
            return false;
        }

        let isMismatch = false;
        a.set.forEach(aCandles => {
            if (!b.set.has(aCandles)) {
                // set mismatch and exit the loop - no way to exit loop with a value
                isMismatch = true;
                return;
            }
        });

        return !isMismatch;
    }

    minMax(): { min: number; max: number } {
        let gMin = Number.POSITIVE_INFINITY;
        let gMax = Number.NEGATIVE_INFINITY;

        this.set.forEach(candles => {
            const { min, max } = candles.minMax();
            gMin = Math.min(gMin, min);
            gMax = Math.max(gMax, max);
        });

        return { min: gMin, max: gMax };
    }

    startEnd(): { start: Date; end: Date } {
        let gStart = new Date(0);
        let gEnd = new Date(Date.now() + 1e10);

        this.set.forEach(candles => {
            const { start, end } = candles.startEnd();
            gStart = moment(start).isBefore(gStart) ? start : gStart;
            gEnd = moment(end).isAfter(gEnd) ? end : gEnd;
        });

        return { start: gStart, end: gEnd };
    }
}

export class Candles {
    symbol: string;
    exchange?: string;
    candles: MarketCandle[] = [];
    _colors: string[] = [];
    bestBids: BBOLine = null;
    bestAsks: BBOLine = null;
    private colorChangeSubject = new Subject<void>();
    colorChange$ = this.colorChangeSubject.asObservable();

    static startEnd(candles: { DT: Date }[]): { start: Date; end: Date } {
        if (!Array.isArray(candles) || candles.length === 0) {
            return null;
        }
        return { start: candles[0].DT, end: candles[candles.length - 1].DT };
    }

    constructor(dto: ICandlesDTO) {
        this.symbol = dto.symbol;
        this.exchange = dto.exchange;
        this.colors = dto.colors || [DEFAULT_CANDLES_COLOR];
        this.candles = !Array.isArray(dto.candles)
            ? []
            : dto.candles.map(candle => new MarketCandle(candle, this.colors[0]));
    }

    destroy(): void {
        this.colorChangeSubject.complete();
    }

    get colors(): string[] {
        return this._colors || [];
    }
    set colors(colors: string[]) {
        if (Array.isArray(colors) && Array.isArray(this._colors) && colors.every((c, i) => c === this._colors[i])) {
            // nothing changed
            return;
        }

        this._colors = colors;

        if (!this._colors) {
            return;
        }

        if (this.candles) {
            this.candles.forEach(c => (c.color = colors[0] || DEFAULT_CANDLES_COLOR));
        }
        if (this._colors?.length > 1) {
            this.enableBBO();
        } else {
            this.disableBBO();
        }

        this.colorChangeSubject.next();
    }

    get isBBOEnabled(): boolean {
        return !!this.bestBids && !!this.bestAsks;
    }

    private enableBBO() {
        this.bestBids = new BBOLine(this, 'bid');
        this.bestAsks = new BBOLine(this, 'ask');
    }

    private disableBBO() {
        this.bestBids = null;
        this.bestAsks = null;
    }

    /**
     * Calculate the minimum Low and maximum High of market data
     */
    minMax(candles: { Close: number }[] = this.candles): { min: number; max: number } {
        const minMax = { min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY };

        const globalMinMax = candles.reduce((lastMinMax, marketCandle) => {
            return {
                min: Math.min(marketCandle.Close, lastMinMax.min),
                max: Math.max(marketCandle.Close, lastMinMax.max)
            };
        }, minMax);

        // Calculate BBO only once, at the root.
        if (candles === this.candles && this.isBBOEnabled) {
            const asksMinMax = this.minMax(this.bestAsks.candles);
            const bidsMinMax = this.minMax(this.bestBids.candles);
            globalMinMax.min = Math.min(globalMinMax.min, asksMinMax.min, bidsMinMax.min);
            globalMinMax.max = Math.max(globalMinMax.max, asksMinMax.max, bidsMinMax.max);
        }

        return globalMinMax;
    }

    startEnd(): { start: Date; end: Date } {
        return Candles.startEnd(this.candles);
    }
}

export class CandlesSetV2 extends CandlesSet {
    private static fieldsMap: ArrayPropsParser[] = [
        {
            name: 'solidusClientId',
            parse: field => field || null
        },
        {
            name: 'exchange',
            parse: field => field || null
        },
        {
            name: 'symbol',
            parse: field => field || null
        },
        {
            name: 'time',
            parse: field => new Date(field).valueOf() || null
        },
        {
            name: 'open',
            parse: field => field || null
        },
        {
            name: 'close',
            parse: field => field || null
        },
        {
            name: 'low',
            parse: field => field || null
        },
        {
            name: 'high',
            parse: field => field || null
        },
        {
            name: 'count',
            parse: field => field || null
        },
        {
            name: 'volume',
            parse: field => field || null
        },
        {
            name: 'bestBid',
            parse: field => field || null
        },
        {
            name: 'bestAsk',
            parse: field => field || null
        },
        {
            name: 'real',
            parse: field => JSON.parse(field)
        }
    ];

    constructor(dto: CandlesListDTOv2) {
        const mappedDto = dto.map(data => {
            return {
                ...data,
                candles: CandlesSetV2.mapCandlesV2toV1(data.candles)
            } as ICandlesDTO;
        });
        super(mappedDto);
    }

    private static mapCandlesV2toV1(candles: ICandleDTOv2[]): ICandleDTO[] {
        return candles.map(candleArr => {
            return CandlesSetV2.fieldsMap.reduce((acc, item, index) => {
                acc[item.name] = item.parse(candleArr[index]);
                return acc;
            }, {} as ICandleDTO);
        });
    }
}

export class BBOLine {
    symbol: string;
    exchange?: string;
    color: string;
    candles: { DT: Date; Close: number }[];

    constructor(candles: Candles, side: 'bid' | 'ask') {
        const isBid = side === 'bid';
        this.symbol = candles.symbol;
        this.exchange = candles.exchange;
        this.color = isBid ? candles.colors[2] : candles.colors[1];
        this.candles = candles.candles.map(c => ({ DT: c.DT, Close: isBid ? c.bestBid : c.bestAsk }));
    }
}

export class MarketCandle {
    DT: Date;
    Open: number;
    Close: number;
    Low: number;
    High: number;
    Volume: number;
    real: boolean;
    color: string;
    bestBid: number;
    bestAsk: number;

    constructor(candle: ICandleDTO, color: string) {
        this.DT = new Date(candle.time);
        this.Open = parseFloat(candle.open);
        this.Close = parseFloat(candle.close);
        this.Low = parseFloat(candle.low);
        this.High = parseFloat(candle.high);
        this.Volume = parseFloat(candle.volume);
        this.real = candle.real === undefined || candle.real === null ? true : candle.real;
        this.color = color;
        this.bestAsk = parseFloat(candle.bestAsk);
        this.bestBid = parseFloat(candle.bestBid);
    }
}
