import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ITooltipParams } from 'ag-grid-enterprise';

import { CellTooltipComponent } from 'src/app/ag-grid-tables/shared/components/cell-tooltip/cell-tooltip.component';

import moment from 'moment';

export type DateCellTooltipParams = ITooltipParams & { tooltipDateFormat: string };

@Component({
    selector: 'trds-date-cell-tooltip',
    templateUrl: './cell-tooltip.component.html',
    styleUrls: ['./cell-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateCellTooltipComponent extends CellTooltipComponent {
    agInit(params: DateCellTooltipParams): void {
        this.canShowTooltip = params.value && moment(params.value).isValid();
        if (this.canShowTooltip) {
            this.tooltipValue = `Local: ${moment(params.value).format(params.tooltipDateFormat)}`;
        }
    }
}
