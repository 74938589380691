import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdvancedSearchGuard } from 'src/app/_guards/advanced-search.guard';
import { AuthGuard } from './_guards/loggedin.guard';

import { VisibilityGuard } from './_guards/visibility.guard';
import { InsufficientPermissionPage } from './_global/insufficient-permission/insufficient-permission.page';
import { PageNotFoundPage } from './_global/page-not-found/page-not-found.page';

// Structure based on
// https://stackoverflow.com/questions/48188541/how-to-have-a-sidenav-with-router-outlet-and-a-separate-login-with-router-outlet
const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
    },
    {
        path: 'insufficient-permission',
        component: InsufficientPermissionPage,
        canActivate: [AuthGuard]
    },
    {
        path: 'ucm',
        loadChildren: () => import('./ucm/ucm.module').then(m => m.UCMModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'market-inspection-v2',
        loadChildren: () =>
            import('./market-inspection-new/market-inspection.module').then(m => m.MarketInspectionNewModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'algos',
        loadChildren: () => import('./algo-thresholds/algo-thresholds.module').then(m => m.AlgoThresholdsModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'ucv',
        loadChildren: () => import('./ucv/ucv.module').then(m => m.UcvModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'system-info',
        loadChildren: () => import('./system/system-info.module').then(m => m.SystemInfoModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'advanced-search',
        loadChildren: () => import('./advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule),
        canActivate: [VisibilityGuard, AdvancedSearchGuard]
    },
    {
        path: 'model-testing',
        loadChildren: () => import('src/app/model-testing/model-testing.module').then(m => m.ModelTestingModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'risk-model',
        loadChildren: () => import('./risk-model/risk-model.module').then(m => m.RiskModelModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'policy',
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule),
        canActivate: [VisibilityGuard]
    },
    {
        path: 'notification-settings',
        loadChildren: () =>
            import('./notification-settings/notification-settings.module').then(m => m.NotificationSettingsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'page-404',
        component: PageNotFoundPage
    },
    { path: '**', component: PageNotFoundPage }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
