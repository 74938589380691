import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, mapTo, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { DialogWidthConfig } from 'src/app/_shared/dialog-error/dialog-error.component';
import { NotifyConfig, NotifyDialogComponent } from 'src/app/_shared/notify-dialog/notify-dialog.component';

@Component({
    selector: 'trds-global-notifications',
    template: ''
})
export class GlobalNotificationsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    constructor(private authService: AuthService, private dialog: MatDialog, private router: Router) {}

    ngOnInit(): void {
        this.passwordExpiresInPopupListener();
    }

    private passwordExpiresInPopupListener(): void {
        this.authService.passwordExpiresInPopup$
            .pipe(
                filter(expiresIn => expiresIn !== null),
                switchMap(expiresIn => this.changePasswordDialog(expiresIn)),
                takeUntil(this.destroy$)
            )
            .subscribe(emailSent => {
                if (emailSent) {
                    this.router.navigateByUrl('/auth/update-password-notification');
                }
                this.authService.resetPasswordExpiresInPopup();
            });
    }

    private changePasswordDialog(expiresIn: number): Observable<boolean> {
        return this.dialog
            .open<NotifyDialogComponent, NotifyConfig, boolean>(NotifyDialogComponent, {
                data: {
                    type: 'INFO',
                    title: 'Time to change your password',
                    body: `Your password will expire in ${expiresIn} days`,
                    continueButtonName: 'Change password',
                    showCancel: true,
                    awaitingObservable: this.authService.sendChangePasswordMail().pipe(mapTo(() => true)),
                    errorMessage: 'There was a problem sending change password email'
                },
                width: DialogWidthConfig.SMALL
            })
            .afterClosed();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
