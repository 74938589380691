import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ITooltipParams } from 'ag-grid-enterprise';
import { getTooltipForIdsContent } from 'src/app/_shared/shared.functions';

import { CellTooltipComponent } from 'src/app/ag-grid-tables/shared/components/cell-tooltip/cell-tooltip.component';

@Component({
    selector: 'trds-ids-content-cell-tooltip',
    template: `
        <div *ngIf="canShowTooltip" class="cell-tooltip-value size-lg">
            <span [innerHTML]="tooltipValue"></span>
        </div>
    `,
    styleUrls: ['./cell-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdsContentCellTooltipComponent extends CellTooltipComponent {
    agInit(params: ITooltipParams): void {
        this.canShowTooltip = params.value.length > 1;
        this.tooltipValue = getTooltipForIdsContent(params.value, true);
    }
}
