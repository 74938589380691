import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ITooltipAngularComp } from 'ag-grid-angular';
import { Column, ITooltipParams } from 'ag-grid-enterprise';

import { isNullOrUndefined } from 'src/app/_shared/shared.functions';
import { TextWidthService } from 'src/app/ag-grid-tables/shared/components/cell-tooltip/text-width.service';

@Component({
    selector: 'trds-cell-tooltip',
    templateUrl: './cell-tooltip.component.html',
    styleUrls: ['./cell-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellTooltipComponent implements ITooltipAngularComp {
    canShowTooltip = false;
    tooltipValue = '';

    constructor(private textWidthService: TextWidthService) {}

    agInit(params: ITooltipParams): void {
        this.tooltipValue = params.value;

        const cellElement: HTMLElement = this.getCellElement((params.column as Column).getColDef().field);
        if (isNullOrUndefined(cellElement)) {
            return;
        }

        const cellWidth: number = cellElement.clientWidth - this.getCellPadding(cellElement);
        this.canShowTooltip = cellWidth < this.textWidthService.getWidth(this.tooltipValue, cellElement);
    }

    private getCellElement(columnId: string): HTMLElement {
        return document.querySelector(`.ag-cell-value[col-id="${columnId}"]`);
    }

    private getCellPadding(el: HTMLElement): number {
        const cellElementStyle: CSSStyleDeclaration = window.getComputedStyle(el);
        const getValue = (property: string) => +cellElementStyle.getPropertyValue(property).replace('px', '');

        return getValue('padding-left') + getValue('padding-right');
    }
}
