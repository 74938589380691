<div class="trds-sidenav">
    <mat-toolbar>
        <nav>
            <div #ucmCompanyLogo class="app-logo">
                <mat-icon [svgIcon]="isLogoAnimated ? 'loop' : 'static'"></mat-icon>
            </div>
            <ul>
                <li *ngIf="isVisible$('ucm') | async">
                    <a
                        tabindex="0"
                        auto_id="btn_RiskDashboard"
                        #ucm
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/ucm'"
                        [class.active]="isActive$('ucm/overview') | async"
                        aria-label="Risk Dashboard"
                        class="link"
                        ngxTippy="Risk Dashboard"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/ucm')"
                    >
                        <mat-icon fontSet="material-icons-outlined">folder</mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('advanced-search') | async">
                    <a
                        auto_id="btn_AdvancedSearch"
                        #advancedSearch
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/advanced-search'"
                        [class.active]="isActive$('advanced-search') | async"
                        aria-label="Advanced Search"
                        class="link"
                        ngxTippy="Advanced Search"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/advanced-search')"
                    >
                        <mat-icon [svgIcon]="'advanced-search'"></mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('market-inspection') | async">
                    <a
                        auto_id="btn_MarketInspection"
                        #marketInspection
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/market-inspection-v2'"
                        [class.active]="isActive$('market-inspection-v2') | async"
                        aria-label="Market Inspection"
                        class="link"
                        ngxTippy="Market Inspection"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/market-inspection-v2')"
                    >
                        <mat-icon>multiline_chart</mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('algos') | async">
                    <a
                        auto_id="btn_AlgorithmSettings"
                        #algos
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/algos'"
                        [class.active]="isActive$('algos') | async"
                        aria-label="Algorithm Settings"
                        class="link"
                        ngxTippy="Algorithm Settings"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/algos')"
                    >
                        <mat-icon fontSet="material-icons-outlined">tune</mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('model-testing') | async">
                    <a
                        auto_id="btn_ModelTesting"
                        #modelTestingNew
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/model-testing'"
                        [class.active]="isActive$('model-testing') | async"
                        aria-label="Model Testing"
                        class="link"
                        ngxTippy="Model Testing"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/model-testing')"
                    >
                        <mat-icon fontSet="material-icons-outlined">science</mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('ucv') | async">
                    <a
                        auto_id="btn_UniversalClientView"
                        #ucv
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/ucv'"
                        [class.active]="isActive$('ucv') | async"
                        aria-label="Universal Client View"
                        class="link"
                        ngxTippy="Universal Client View"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/ucv')"
                    >
                        <mat-icon fontSet="material-icons-outlined">people</mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('onboarding') | async">
                    <a
                        auto_id="btn_Onboarding"
                        #onboarding
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/onboarding'"
                        [class.active]="isActive$('onboarding') | async"
                        aria-label="Onboarding"
                        class="link"
                        ngxTippy="Onboarding"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/onboarding')"
                    >
                        <mat-icon [svgIcon]="'onboarding'"></mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('policy') | async">
                    <a
                        auto_id="btn_Policy"
                        #kycPolicy
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/policy'"
                        [class.active]="isActive$('policy') | async"
                        aria-label="Policy"
                        class="link"
                        ngxTippy="Policy"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/policy')"
                    >
                        <mat-icon [svgIcon]="'kyc-policy'"></mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('system-info') | async">
                    <a
                        auto_id="btn_DataAudit"
                        #systemInfo
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/system-info'"
                        [class.active]="isActive$('system-info') | async"
                        aria-label="Data Audit"
                        class="link"
                        ngxTippy="Data Audit"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/system-info')"
                    >
                        <mat-icon fontSet="material-icons-outlined">dvr</mat-icon>
                    </a>
                </li>

                <li *ngIf="isVisible$('risk-model') | async">
                    <a
                        auto_id="btn_RiskModel"
                        #riskModel
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/risk-model'"
                        [class.active]="isActive$('risk-model') | async"
                        aria-label="Risk Model"
                        class="link"
                        ngxTippy="Risk Model"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/risk-model')"
                    >
                        <mat-icon [svgIcon]="'speed'"></mat-icon>
                    </a>
                </li>

                <!-- THIS MODULE MUST REMAIN LAST -->
                <li *ngIf="isVisible$('admin') | async">
                    <a
                        auto_id="btn_AdminSettings"
                        #admin
                        mat-icon-button
                        disableRipple
                        [routerLink]="'/admin'"
                        [class.active]="isActive$('admin') | async"
                        aria-label="Admin Settings"
                        class="link"
                        ngxTippy="Admin Settings"
                        [tippyProps]="{ placement: 'right' }"
                        tippyClassName="trds-tooltip"
                        color="primary"
                        (keydown)="handleKeyDown($event, '/admin')"
                    >
                        <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                    </a>
                </li>
                <!-- THIS MODULE MUST REMAIN LAST -->
            </ul>
        </nav>
    </mat-toolbar>
</div>
