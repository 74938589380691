import { Router } from '@angular/router';

import { SessionStorage } from '../../../../_shared/session-storage/session-storage.service';

export enum CaseDetailsOrigin {
    UCM = 'ucm',
    UCV = 'ucv',
    ModelTesting = 'model_testing'
}

export type CaseDetailsParams = {
    origin: CaseDetailsOrigin;
    clientId?: string;
    modelTestId?: string;
};

export const DefaultCaseDetailsParams: CaseDetailsParams = {
    origin: CaseDetailsOrigin.UCM
};

export enum PANELS {
    ClientDataView
}

/**
 * Stores CaseDetailsParams to session storage to remember from which module (UCV or UCM) user comes to CaseDetailsPage.
 * So, breadcrumbs always consist of pages from the correct module, even on page refresh.
 */
export class CaseDetailsParamsStorage {
    private readonly CaseDetailsParamsStorageKey = 'caseDetailsParams';

    constructor(private router: Router, private storage: SessionStorage) {
        const params = this.getCaseDetailsParamsFromRoute();
        if (params) {
            storage.set(this.CaseDetailsParamsStorageKey, JSON.stringify(params));
        }
    }

    get params(): CaseDetailsParams {
        const storedParams = this.storage.get(this.CaseDetailsParamsStorageKey);
        if (!storedParams) {
            return DefaultCaseDetailsParams;
        }

        return JSON.parse(storedParams);
    }

    private getCaseDetailsParamsFromRoute(): CaseDetailsParams | null {
        return this.router.getCurrentNavigation()?.extras?.state?.caseDetailsParams;
    }
}
