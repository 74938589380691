import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

import { AdvancedSearchService } from 'src/app/advanced-search/advanced-search-service/advanced-search.service';
import { TableType } from 'src/app/advanced-search/advanced-search.models';
import { LinkContextService } from '../_services/link-context/link-context.service';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { LoggingService } from '../_logging/logging.service';

@Injectable({
    providedIn: 'root'
})
export class AdvancedSearchGuard {
    constructor(
        private advancedSearch: AdvancedSearchService,
        private linkContextService: LinkContextService,
        private log: LoggingService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const caseIds: string[] | undefined = route.queryParamMap.get('caseIds')?.split(',');
        const alertIds: string[] | undefined = route.queryParamMap.get('alertIds')?.split(',');
        const contextId: string | undefined = route.queryParamMap.get('contextId');

        if (contextId?.length) {
            return this.linkContextService.getAdvancedSearchContextById(contextId).pipe(
                catchError(e => {
                    this.log.error(`FAILED_FETCH_CONTEXT contextId ${contextId}, message: ${JSON.stringify(e)}`);
                    return of(null);
                }),
                tap(context => {
                    if (context) {
                        this.advancedSearch.transferRouteData.setData(context);
                    }
                }),
                mapTo(true)
            );
        }

        if (caseIds?.length) {
            this.advancedSearch.transferRouteData.setData({ [TableType.Cases]: { caseId: caseIds } });
        } else if (alertIds?.length) {
            this.advancedSearch.transferRouteData.setData({ [TableType.Alerts]: { alertId: alertIds } });
        }

        return true;
    }
}
