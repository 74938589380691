import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Auth, Auth2FAStatus } from '../../auth/auth.model';
import { UserService } from '../../_services/users/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
    NotificationOptions,
    NotificationSnackBarComponent,
    NotificationType
} from '../notification-snackbar/notification-snackbar.component';
import { getAuthErrorMessage, getErrorMessage, qrCode2fa } from '../shared.functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggingService } from '../../_logging/logging.service';
import { SSO_CLIENT_ID } from '../shared.models';

export enum ReAuthStep {
    SSO = 'SSO',
    PASSWORD = 'PASSWORD',
    REGISTER_2FA = 'REGISTER_2FA',
    INPUT_2FA = 'INPUT_2FA'
}

@Component({
    selector: 'trds-re-auth-dialog',
    templateUrl: './re-auth-dialog.component.html',
    styleUrls: ['../notify-dialog/notify-dialog.component.scss', './re-auth-dialog.component.scss']
})
export class ReAuthDialogComponent implements OnDestroy {
    @HostBinding('class') class = 'trds-re-auth-dialog trds-dialog';

    public pending = false;
    public currentStep: ReAuthStep = ReAuthStep.PASSWORD;
    public reAuthSteps = ReAuthStep;
    public password: string;
    public secretKey: string;
    public qrCode: string;
    public verificationStatus: Auth2FAStatus;

    public capsOn: boolean;
    public showPassword: boolean;

    public formControl = new FormControl(null, [Validators.required]);

    private destroy$ = new Subject<void>();

    private titles: { [key in ReAuthStep]: string } = {
        [ReAuthStep.SSO]: 'Your authenticated session has expired!',
        [ReAuthStep.PASSWORD]: 'Your authenticated session has expired!',
        [ReAuthStep.INPUT_2FA]: 'Two-factor authentication',
        [ReAuthStep.REGISTER_2FA]: 'Set up two-factor authentication'
    };

    private reAuthActions: { [key in ReAuthStep]: () => void } = {
        [ReAuthStep.SSO]: () => this.reAuthWithSSO(),
        [ReAuthStep.PASSWORD]: () => this.reAuthWithPasswordStep(),
        [ReAuthStep.INPUT_2FA]: () => void 0,
        [ReAuthStep.REGISTER_2FA]: () => (this.currentStep = ReAuthStep.INPUT_2FA)
    };

    clientIdSSO: string;

    constructor(
        private dialogRef: MatDialogRef<ReAuthDialogComponent>,
        private dialog: MatDialog,
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private snackBar: MatSnackBar,
        private log: LoggingService
    ) {
        this.clientIdSSO = sessionStorage.getItem(SSO_CLIENT_ID);
        if (this.clientIdSSO) {
            this.currentStep = ReAuthStep.SSO;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public goToForgotPassword(): Promise<boolean> {
        this.authService.logout();
        this.dialog.closeAll();
        return this.router.navigate(['/auth/forgot-password'], {
            queryParams: { returnUrl: this.router.routerState.snapshot.url }
        });
    }

    public confirm(): void {
        this.reAuthActions[this.currentStep]();
    }

    get buttonText(): string {
        if (this.currentStep === ReAuthStep.SSO || this.currentStep === ReAuthStep.REGISTER_2FA) {
            return 'Continue';
        }
        return 'Authenticate';
    }

    get titleText(): string {
        return this.titles[this.currentStep];
    }

    public verify2FA(code: string): void {
        this.verificationStatus = Auth2FAStatus.VERIFYING;
        this.authService.authenticate(code, this.userService.user.id.toString(), false).subscribe({
            next: auth => {
                this.verificationStatus = Auth2FAStatus.SUCCESS;
                this.successReAuth(auth);
            },
            error: err => {
                this.verificationStatus = Auth2FAStatus.ERROR;
                this.errorLogin(err);
            }
        });
    }

    private reAuthWithSSO(): void {
        this.pending = true;
        this.authService.loginWithAzure(this.authService.getSSOToken()).subscribe({
            next: auth => this.successReAuth(auth),
            error: err => this.errorLogin(err)
        });
    }

    private reAuthWithPasswordStep(): void {
        this.authService.login(this.userService.user.email, this.formControl.value).subscribe({
            next: authResponse => {
                this.secretKey = authResponse.secretKey;
                this.qrCode = qrCode2fa(authResponse.secretKey);

                if (authResponse.secretKey && authResponse.userId) {
                    this.currentStep = ReAuthStep.REGISTER_2FA;
                } else if (authResponse.userId) {
                    this.currentStep = ReAuthStep.INPUT_2FA;
                } else {
                    this.successReAuth(authResponse);
                }
            },
            error: err => this.errorLogin(err)
        });
    }

    private successReAuth(authResponse: Auth): void {
        this.dialogRef.close();
        this.userService.setUser(authResponse.user);
    }

    private errorLogin(error: HttpErrorResponse): void {
        this.pending = false;
        const errorMessage = getAuthErrorMessage(error);

        this.log.error(`Failed to re-authenticate. Error: ${getErrorMessage(error)}`);
        this.snackBar.openFromComponent(NotificationSnackBarComponent, {
            ...NotificationOptions[NotificationType.ERROR],
            data: errorMessage
        });
    }
}
