import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    TemplateRef
} from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatDateRangePicker } from '@angular/material/datepicker';

import { DatePickerPresets } from './date-range-picker-presets/date-range-picker-presets.component';
import { DefaultPresets } from '../../tables-with-filters/shared.models';
import { TimeRangeInputsComponent } from './time-range-inputs/time-range-inputs.component';
import { isNullOrUndefined } from 'src/app/_shared/shared.functions';

import moment, { Moment } from 'moment';

@Component({
    selector: 'trds-date-range-picker-header',
    templateUrl: './date-range-picker-header.component.html',
    styleUrls: ['./date-range-picker-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerHeaderComponent implements OnDestroy {
    @HostBinding('class') hostClass = 'trds-date-range-picker-header';

    public years: number[] = [];
    public ComponentType = DateRangePickerHeaderComponent;
    public static projectedTemplate: TemplateRef<any>;
    public static datePresets: DatePickerPresets[] = [...DefaultPresets];
    public static hasTimePicker = false;
    public static timeRangeInputsComponent: TimeRangeInputsComponent | null = null;

    private readonly destroy$ = new Subject<void>();
    public readonly startYear = 2009;
    public readonly currentYear = this.dateAdapter.getYear(this.momentUtc);

    constructor(
        private calendar: MatCalendar<Moment>,
        private dateAdapter: MomentDateAdapter,
        private picker: MatDateRangePicker<Moment>,
        @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef
    ) {
        // make sure the header stays in sync with the calendar
        calendar.stateChanges.pipe(takeUntil(this.destroy$)).subscribe(() => cdr.markForCheck());
        this.setMinAndMaxDatesIfNeeded(calendar);

        this.initYears();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        DateRangePickerHeaderComponent.projectedTemplate = null;
        DateRangePickerHeaderComponent.datePresets = [...DefaultPresets];
        DateRangePickerHeaderComponent.hasTimePicker = false;
    }

    get periodLabel(): string {
        return this.dateAdapter
            .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    get hasProjectedTemplate(): boolean {
        return DateRangePickerHeaderComponent.projectedTemplate instanceof TemplateRef;
    }

    get momentUtc(): Moment {
        return moment.utc();
    }

    onPreviousClicked(mode: 'month' | 'year'): void {
        this.changeDate(mode, -1);
    }

    onNextClicked(mode: 'month' | 'year'): void {
        this.changeDate(mode, 1);
    }

    onTodayBtnClick(): void {
        this.calendar.activeDate = this.momentUtc;
    }

    onYearSelectClicked(year: number): void {
        const date = this.momentUtc.startOf('day');
        this.calendar.activeDate = this.dateAdapter.addCalendarYears(date, -(this.currentYear - year));
    }

    setTimeRangeInputsComponent(component: TimeRangeInputsComponent): void {
        DateRangePickerHeaderComponent.timeRangeInputsComponent = component;
    }

    // increment or decrement month or year
    private changeDate(mode: 'month' | 'year', amount: -1 | 1): void {
        this.calendar.activeDate =
            mode === 'month'
                ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, amount)
                : this.dateAdapter.addCalendarYears(this.calendar.activeDate, amount);
    }

    private initYears(): void {
        this.years = [...Array(this.currentYear - this.startYear + 1).keys()].map(x => x + this.startYear);
    }

    private setMinAndMaxDatesIfNeeded(calendar: MatCalendar<Moment>): void {
        if (isNullOrUndefined(calendar.minDate)) {
            calendar.minDate = this.momentUtc.set('year', this.startYear).startOf('year');
        }
        if (isNullOrUndefined(calendar.maxDate)) {
            calendar.maxDate = this.momentUtc.set('year', this.currentYear).endOf('year');
        }
    }
}
