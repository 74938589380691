import { Injectable, OnDestroy } from '@angular/core';
import { DetailedUser } from '../../_shared/shared.models';
import { forkJoin, Observable, Subject } from 'rxjs';
import { LoggingService } from '../../_logging/logging.service';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { getErrorMessage, isNullOrUndefined } from '../../_shared/shared.functions';
import { cloneDeep } from 'lodash';

export interface IPendo {
    initialize(param: { visitor: any; account: any }): void;
    clearSession(): void;
}

export interface IPendoOps {
    user$: Observable<DetailedUser>;
    solidusClientId$: Observable<string>;
}

@Injectable({
    providedIn: 'root'
})
export class PendoService implements OnDestroy {
    private readonly destroy$ = new Subject<void>();
    private readonly pendo: IPendo = window['pendo'];

    constructor(private log: LoggingService) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public initialize(options: IPendoOps): void {
        const user$ = options.user$.pipe(
            filter(user => !isNullOrUndefined(user)),
            map(user => cloneDeep(user)),
            first()
        );
        const solidusClientId$ = options.solidusClientId$.pipe(
            filter(data => !isNullOrUndefined(data)),
            map(data => JSON.parse(atob(data))),
            first()
        );

        forkJoin([user$, solidusClientId$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ([visitor, account]) => {
                    try {
                        this.pendo.initialize({ visitor, account });
                    } catch (error) {
                        this.log.error(`Failed to initialize Pendo. Error: ${getErrorMessage(error)}`);
                    }
                },
                error => {
                    this.log.error(`Failed to initialize Pendo. Error: ${getErrorMessage(error)}`);
                }
            );
    }

    public clear(): void {
        try {
            this.pendo.clearSession();
        } catch (error) {
            this.log.error(`Failed to clear Pendo session. Error: ${getErrorMessage(error)}`);
        }
    }
}
