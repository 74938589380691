import { TemplateRef } from '@angular/core';

import { Observable } from 'rxjs';

import {
    AdvancedMultiSelectItem,
    AdvancedMultiSelectItemValue
} from 'src/app/_shared/advanced-column-setting/advanced-column-setting.model';
import { DatePickerPresets } from 'src/app/_shared/date-range-picker-header/date-range-picker-presets/date-range-picker-presets.component';
import { CaseTimeInStatusSettings } from '../ucm/cases/models';

export interface IColumnFilters {
    [key: string]: AdvancedMultiSelectItemValue;
}

export interface ITableConfig {
    resolvedAtTemplateRef?: TemplateRef<any>;
    isFlaggedTemplateRef?: TemplateRef<any>;
    timeInStatusTemplateRef?: TemplateRef<any>;
    statusSelectItems?: AdvancedMultiSelectItem[];
    getTimeInStatusSettings?: Observable<CaseTimeInStatusSettings>;
    tableCssClasses?: string[];
    getStats?(columnKey: string, filters: IColumnFilters): Observable<{ [key: string]: number }>;
    searchByInputValue?(columnKey: string, value: string): Observable<AdvancedMultiSelectItem[]> | null;
}

export interface ICellClicked<T> {
    columnKey: string;
    payload: T;
}

export const timeInStatusColor = {
    onSchedule: '#729f56',
    behindSchedule: '#d38248',
    offSchedule: '#ff735a'
};

export const DATE_KEYS = [
    'createdAt',
    'updatedAt',
    'resolvedAt',
    'submittedAt',
    'approvedAt',
    'timestamp',
    'date',
    'fromTime',
    'toTime',
    'flaggedAt',
    'lastReviewedAt'
];

export const ADVANCED_MULTISELECT_ITEM_KEYS = [
    'createdBy',
    'updatedBy',
    'approvedBy',
    'primaryClients',
    'primaryAccounts',
    'primaryActors',
    'counterPartyClients',
    'counterPartyAccounts',
    'counterPartyActors',
    'accounts',
    'actors',
    'clients',
    'symbols',
    'symbol',
    'assignedTo',
    'assignedTeam',
    'escalatedBy',
    'escalatedTeam',
    'escalee',
    'alertTypes',
    'alertType',
    'name',
    'acronym',
    'version',
    'taxId',
    'clientProfileName',
    'msClientProfileName',
    'countryOfDomicile',
    'countryOfIncorporation',
    'applicationId',
    'signUpEmail',
    'reviewedBy',
    'activity',
    'user',
    'fileName',
    'incomingRecords',
    'processed',
    'errors',
    'duplicates',
    'tags',
    'id',
    'caseId',
    'fullName',
    'contactEmail',
    'clientId',
    'clientProfile',
    'manipulationValue',
    'thresholdBreach',
    'thresholdValue',
    'totalDepositCount',
    'totalWithdrawalCount',
    'alertId',
    'jurisdiction',
    'actorId',
    'externalAccountId',
    'internalAccountId',
    'currency',
    'quantity',
    'quantityOld',
    'localCurrencyAmount',
    'transactionHash',
    'usdNotional',
    'usdNotionalOld',
    'clientIdOrName'
];

export const EMIT_FIRST_VALUE_ONLY = [
    'manipulationValue',
    'thresholdValue',
    'totalDepositCount',
    'totalWithdrawalCount'
];

export const PREDEFINED_STRING_FILTER_KEYS = [
    'score',
    'status',
    'caseStatus',
    'origins',
    'priority',
    'pendingActionType',
    'resolutionFlag',
    'investorType',
    'resolutionType',
    'alertTypeOrigin',
    'type',
    'risk',
    'recordType',
    'strReportStatus',
    'gender',
    'alertStrReportStatuses',
    'externalAccountType',
    'externalAccountFunction',
    'transactionType',
    'timeInStatus',
    'reviewStatus'
];

export const ADVANCED_SEARCH_TRUNCATED_FIELDS = ['transactionHash'];

export const DefaultPresets = [
    DatePickerPresets.Today,
    DatePickerPresets.Last7Days,
    DatePickerPresets.Last30Days,
    DatePickerPresets.Last90Days
];

export enum ColumnFilterType {
    SortOnly = 'SortOnly',
    Date = 'Date',
    Autocomplete = 'Autocomplete',
    Multiselect = 'Multiselect'
}

export interface IColumnWithFiltersConfig {
    name: string;
    key: string;
    searchKey?: string;
    propertyKey?: string;
    filterType?: ColumnFilterType;
    fullName?: string;
    notSelectable?: boolean;
    hiddenByDefault?: boolean;
    shouldLoadStats?: boolean;
    cssClasses?: string[];
    dateTooltipFormat?: 'MMM Do YYYY, HH:mm';
    dateValueFormat?:
        | 'MMM Do YYYY'
        | 'MMM DD, YYYY'
        | 'MMM Do YYYY, HH:mm'
        | 'MMM D, y, H:mm:ss'
        | 'MMM D, y, H:mm'
        | 'MMM DD YYYY, HH:mm:ss.SSS'
        | 'MMM DD YYYY, HH:mm';
    showTimezone?: boolean;
    useAlgoDictionaryName?: boolean;
    templateRef?: TemplateRef<any>;
    isCaseStatus?: boolean;
    isApplicationStatus?: boolean;
    isClientStatus?: boolean;
    isActivityStatus?: boolean;
    isTransactionStatus?: boolean;
    dateRanges?: DatePickerPresets[];
    midSlice?: number;
    isSticky?: boolean;
    isStickyEnd?: boolean;
    disableSorting?: boolean;
    canShowAlertCategory?: boolean;
}

export class ColumnWithFilters {
    constructor(private config: IColumnWithFiltersConfig) {}

    get name(): string {
        return this.config.name;
    }

    set name(name: string) {
        this.config.name = name;
    }

    get key(): string {
        return this.config.key;
    }

    get searchKey(): string | null {
        return this.config.searchKey || null;
    }

    get propertyKey(): string | null {
        return this.config.propertyKey || null;
    }

    get fullName(): string | null {
        return this.config.fullName || null;
    }

    set filterType(type: ColumnFilterType | null) {
        this.config.filterType = type;
    }

    get filterType(): ColumnFilterType | null {
        return this.config.filterType || null;
    }

    get notSelectable(): boolean {
        return this.config.notSelectable || false;
    }

    set hiddenByDefault(hiddenByDefault: boolean) {
        this.config.hiddenByDefault = hiddenByDefault;
    }

    get hiddenByDefault(): boolean {
        return this.config.hiddenByDefault || false;
    }

    get shouldLoadStats(): boolean {
        return this.config.shouldLoadStats || false;
    }

    get cssClasses(): string[] {
        return this.config.cssClasses || [];
    }

    get dateTooltipFormat(): string | null {
        return this.config.dateTooltipFormat || null;
    }

    get dateValueFormat(): string | null {
        return this.config.dateValueFormat || null;
    }

    get useAlgoDictionaryName(): boolean {
        return this.config.useAlgoDictionaryName || false;
    }

    set templateRef(templateRef: TemplateRef<any>) {
        this.config.templateRef = templateRef;
    }

    get templateRef(): TemplateRef<any> | null {
        return this.config.templateRef || null;
    }

    get isCaseStatus(): boolean {
        return this.config.isCaseStatus || false;
    }

    get isApplicationStatus(): boolean {
        return this.config.isApplicationStatus || false;
    }

    get isClientStatus(): boolean {
        return this.config.isClientStatus || false;
    }

    get isActivityStatus(): boolean {
        return this.config.isActivityStatus || false;
    }

    get isTransactionStatus(): boolean {
        return this.config.isTransactionStatus || false;
    }

    get dateRanges(): DatePickerPresets[] {
        return this.config.dateRanges || DefaultPresets;
    }

    get showTimezone(): boolean {
        return this.config.showTimezone || false;
    }

    get midSlice(): number {
        return this.config.midSlice || 3;
    }

    get isSticky(): boolean {
        return this.config.isSticky || false;
    }

    get isStickyEnd(): boolean {
        return this.config.isStickyEnd || false;
    }

    get disableSorting(): boolean {
        return this.config.disableSorting || false;
    }

    get canShowAlertCategory(): boolean {
        return this.config.canShowAlertCategory || false;
    }
}
