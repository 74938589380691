import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { MatButton } from '@angular/material/button';
import { MatIconRegistry } from '@angular/material/icon';

import { camelCase } from 'lodash';

import { VisibilitiesService } from 'src/app/_services/visibilities/visibilities.service';
import { AppConfigService } from 'src/app/app-config.service';
import { TitleService } from 'src/app/_services/title/title.service';

@Component({
    selector: 'trds-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('class') class = 'trds-sidenav';

    public isLogoAnimated: boolean; //for animation

    private navIconBasePath = '/assets/nav-icons/';
    private icons = ['static', 'loop', 'onboarding', 'advanced-search', 'overview', 'cases', 'speed', 'kyc-policy'];

    private destroy$ = new Subject<void>();

    @ViewChild('ucm') public ucmRef: MatButton;
    @ViewChild('advancedSearch') public advancedSearchRef: MatButton;
    @ViewChild('marketInspection') public marketInspectionRef: MatButton;
    @ViewChild('algos') public algosRef: MatButton;
    @ViewChild('modelTesting') public modelTestingRef: MatButton;
    @ViewChild('ucv') public ucvRef: MatButton;
    @ViewChild('onboarding') public onboardingRef: MatButton;
    @ViewChild('systemInfo') public systemInfoRef: MatButton;
    @ViewChild('riskModel') public riskModelRef: MatButton;
    @ViewChild('admin') public adminRef: MatButton;
    @ViewChild('kycPolicy') public kycPolicyRef: MatButton;

    constructor(
        private iconReg: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private appConfigService: AppConfigService,
        private visibilitiesService: VisibilitiesService,
        private titleService: TitleService,
        private router: Router
    ) {
        this.icons.forEach(icon => this.iconReg.addSvgIcon(icon, this.santizieIcon(icon)));
    }

    ngOnInit(): void {
        this.appConfigService.isLogoAnimated$.pipe(takeUntil(this.destroy$)).subscribe(isAnimated => {
            this.isLogoAnimated = isAnimated;
        });
    }

    ngAfterViewInit(): void {
        this.titleService.tabFocus$
            .pipe(
                filter(moduleName => !!moduleName),
                takeUntil(this.destroy$)
            )
            .subscribe(moduleName => {
                this.focusOnTab(moduleName);
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public isVisible$(module: string): Observable<boolean> {
        return this.visibilitiesService.isVisible$(module);
    }

    private santizieIcon(path: string) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(this.navIconBasePath + path + '.svg');
    }

    handleKeyDown(ev: KeyboardEvent, url: string): void {
        ev.stopPropagation();
        if (ev.key === 'ArrowRight') {
            this.titleService.focusOnTitle();
        } else if (ev.key === 'Enter') {
            this.titleService.focusOnTitle();
            this.router.navigateByUrl(url);
        }
    }

    focusOnTab(moduleName: string): void {
        const module = camelCase(moduleName);

        this[`${module}Ref`]?.focus();
    }

    isActive$(module: string): Observable<boolean> {
        return this.getMainPage$().pipe(map(page => page === module));
    }

    private getMainPage$(): Observable<string> {
        return this.titleService.title.pipe(map(title => title[0].route.join('/')));
    }
}
