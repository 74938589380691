export interface FlagModel {
    [key: string]: boolean;
}

export const LD_STORE_KEY = 'ld_flags';

export const domainsToEnvironmentMapper: { [key: string]: string } = {
    localhost: 'development',
    rnd: 'development',
    staging: 'development',
    'prod-us-east-1': 'production',
    'prod-eu-central-1': 'production',
    'prod-ap-southeast-1': 'production',
    fidelity: 'production',
    'uat-eu-central-1': 'uat'
};
