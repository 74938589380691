<div class="layout-container">
    <mat-sidenav-container class="sidenav-paddding">
        <mat-sidenav mode="side" fixedInViewport opened class="main-nav">
            <trds-sidenav></trds-sidenav>
        </mat-sidenav>
        <mat-sidenav-content>
            <trds-breadcrumbs class="breadcrumbs"></trds-breadcrumbs>
            <div class="inner-padding" id="mi-scroll-content" cdkScrollable>
                <ng-content></ng-content>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

<trds-global-notifications></trds-global-notifications>
