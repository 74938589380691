import { HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';

import { SortModelItem } from 'ag-grid-enterprise';

import { MatSortable, Sort } from '@angular/material/sort';

import { PaginationFilter } from '../advanced-search/advanced-search.models';
import {
    ADVANCED_MULTISELECT_ITEM_KEYS,
    DATE_KEYS,
    EMIT_FIRST_VALUE_ONLY,
    IColumnFilters,
    timeInStatusColor
} from 'src/app/tables-with-filters/shared.models';
import {
    AdvancedMultiSelectItem,
    AdvancedMultiSelectItemValue
} from 'src/app/_shared/advanced-column-setting/advanced-column-setting.model';

import { cloneDeep } from 'lodash';
import { EventSide, EventStatus } from 'src/app/_shared/events.models';
import { CaseTags } from 'src/app/ucm/cases/components/_shared/cases.types';
import { Paginated } from 'src/app/_shared/shared.models';
import { getErrorMessage, isNullOrUndefined } from 'src/app/_shared/shared.functions';
import { LoggingService } from 'src/app/_logging/logging.service';
import { AlertSTRStatus } from '../ucm/alerts/alerts.models';

export function getHttpParams(pagination: PaginationFilter, sort: Sort): HttpParams {
    let params = new HttpParams();

    if (pagination) {
        params = params.appendAll({ page: pagination.pageNumber, size: pagination.pageSize });
    }

    if (sort) {
        params = params.appendAll({ sort: `${sort.active},${sort.direction}` });
    }

    return params;
}

export function getAgHttpParams(pagination: PaginationFilter, sort: SortModelItem): HttpParams {
    let params = new HttpParams();

    if (pagination) {
        params = params.appendAll({ page: pagination.pageNumber, size: pagination.pageSize });
    }

    if (sort) {
        params = params.appendAll({ sort: `${sort.colId},${sort.sort}` });
    }

    return params;
}

export function getFiltersPayload(filters: IColumnFilters): { [key: string]: AdvancedMultiSelectItemValue } {
    const payload = cloneDeep(filters) as {
        [key: string]: AdvancedMultiSelectItemValue;
    };

    for (const payloadKey in payload) {
        if (DATE_KEYS.includes(payloadKey)) {
            const payloadDate = payload[payloadKey] as { from: Date; to: Date };
            payload[payloadKey] = { from: payloadDate.from.getTime(), to: payloadDate.to.getTime() };
        }
        if (ADVANCED_MULTISELECT_ITEM_KEYS.includes(payloadKey)) {
            const payloadUser = payload[payloadKey] as AdvancedMultiSelectItem[];
            const values = (payloadUser.map(user =>
                user.valuesToSend?.length ? user.valuesToSend : user.value
            ) as string[])?.flat(1);
            payload[payloadKey] = EMIT_FIRST_VALUE_ONLY.includes(payloadKey) ? values[0] : values;
        }
    }

    return payload;
}

export function getPaginatedResultWithError<T>(
    error: HttpErrorResponse,
    pagination: PaginationFilter,
    sort: Sort,
    filters: IColumnFilters
): Paginated<T> {
    return {
        loadError: error,
        sort,
        filters,
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        total: 0
    } as Paginated<T>;
}

export function getInitDataInResolver<T>(
    logService: LoggingService,
    getSortSetting$: Observable<MatSortable | null>,
    getData$: (paginationFilter: PaginationFilter, sort: Sort, filters: IColumnFilters) => Observable<Paginated<T>>,
    filters: IColumnFilters,
    defaultSort: Sort,
    defaultPagination: PaginationFilter,
    errMsg: string
): Observable<Paginated<T>> {
    return getSortSetting$.pipe(
        catchError(error => {
            logService.error(errMsg + getErrorMessage(error));
            return of(getPaginatedResultWithError<T>(error, defaultPagination, defaultSort, filters));
        }),
        first(),
        switchMap((userSort: MatSortable | null) => {
            const sort: Sort = isNullOrUndefined(userSort)
                ? defaultSort
                : { active: userSort.id, direction: userSort.start };

            return getData$(defaultPagination, sort, filters).pipe(
                catchError(error => {
                    logService.error(errMsg + getErrorMessage(error));
                    return of(getPaginatedResultWithError<T>(error, defaultPagination, sort, filters));
                })
            );
        })
    );
}

export function getScoreItems(): AdvancedMultiSelectItem[] {
    return ['0-49', '50-70', '71-100'].map(value => ({
        name: value,
        value: value
    }));
}

export function getInvestorTypeItems(): AdvancedMultiSelectItem[] {
    return [
        { value: 'RETAIL', name: 'Retail' },
        { value: 'INSTITUTIONAL', name: 'Institutional' },
        { value: 'UNKNOWN', name: 'Undefined' }
    ];
}

export function getCasesTagNames(caseTags: CaseTags[]): AdvancedMultiSelectItem[] {
    if (!caseTags || caseTags.length == 0) return [];

    return caseTags.map(tag => ({ name: tag.name, value: tag.name }));
}

export function getResolutionTypeItems(): AdvancedMultiSelectItem[] {
    return [
        { value: 'APPROVED', name: 'Approved' },
        { value: 'REJECTED', name: 'Rejected' }
    ];
}

export function getApplicationStatuses(): AdvancedMultiSelectItem[] {
    return [
        { value: 'DRAFT', name: 'Draft' },
        { value: 'SUBMITTED', name: 'Submitted' },
        { value: 'ASSIGNED', name: 'Assigned' },
        { value: 'TRANSFERRED', name: 'Transferred' },
        { value: 'RESOLVED', name: 'Resolved' },
        { value: 'DISCARDED', name: 'Discarded' }
    ];
}

export function getTmStatusItems(): AdvancedMultiSelectItem[] {
    return [
        { value: 'NEW', name: 'New' },
        { value: 'APPROVED', name: 'Approved' },
        { value: 'REJECTED', name: 'Rejected' },
        { value: 'CONDITIONALLY_APPROVED', name: 'Conditionally Approved' },
        { value: 'IGNORED', name: 'Ignored' },
        { value: 'UNDER_INVESTIGATION', name: 'Under Investigation' }
    ];
}

export function getTmAccountTypeItems(): AdvancedMultiSelectItem[] {
    return [
        { value: 'FIAT', name: 'Fiat' },
        { value: 'CRYPTO', name: 'Crypto' }
    ];
}

export function getTmAccountFunctionItems(): AdvancedMultiSelectItem[] {
    return [
        { value: 'TRADING', name: 'Trading' },
        { value: 'HEDGING', name: 'Hedging' },
        { value: 'N/A', name: 'N/A' }
    ];
}

export function getTmTypeItems(): AdvancedMultiSelectItem[] {
    return [
        { value: 'DEPOSIT', name: 'Deposit' },
        { value: 'WITHDRAWAL', name: 'Withdrawal' }
    ];
}

export function getStatusItems(): AdvancedMultiSelectItem[] {
    return [
        { value: 'NEW', name: 'New' },
        { value: 'ASSIGNED', name: 'Assigned' },
        { value: 'IN_PROGRESS', name: 'In Progress' },
        { value: 'IN_REVIEW', name: 'In Review' },
        { value: 'RESOLVED', name: 'Resolved' },
        { value: 'PENDING', name: 'Pending' },
        { value: 'ESCALATED', name: 'Escalated' },
        { value: 'TRANSFERRED', name: 'Transferred' }
    ];
}

export function getResolutionFlagItems(): AdvancedMultiSelectItem[] {
    return [
        { name: 'False positive', value: 'FALSE_POSITIVE' },
        { name: 'Adjudicated', value: 'ADJUDICATED' },
        { name: 'Inconclusive', value: 'INCONCLUSIVE' },
        { value: 'RISK_ACCEPTED', name: 'Risk Accepted' }
    ];
}

export function getOriginsItems(): AdvancedMultiSelectItem[] {
    return [
        { name: 'TM', value: 'TM' },
        { name: 'MS', value: 'MS' },
        { name: 'KYC', value: 'KYC' }
    ];
}

export function getPriorityItems(): AdvancedMultiSelectItem[] {
    return [
        { name: 'High', value: 'HIGH' },
        { name: 'Medium', value: 'MEDIUM' },
        { name: 'Low', value: 'LOW' }
    ];
}

export function getPendingActionTypeItems(): AdvancedMultiSelectItem[] {
    return [
        { name: 'Admin action', value: 'ADMIN_ACTION' },
        { name: 'Regulatory response', value: 'REGULATORY_RESPONSE' }
    ];
}

export function getMsEventStatusItems(): AdvancedMultiSelectItem[] {
    return Object.values(EventStatus)
        .filter(event => typeof event !== 'number')
        .map((event: string) => ({ name: event, value: event }));
}

export function getMsEventSideItems(): AdvancedMultiSelectItem[] {
    return Object.values(EventSide)
        .filter(event => typeof event !== 'number')
        .map((event: string) => ({ name: event, value: event }));
}

export function getClientStatus(): AdvancedMultiSelectItem[] {
    return [
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Blocked', value: 'BLOCKED' },
        { name: 'Deactivated', value: 'DEACTIVATED' }
    ];
}

export function getActivityStatus(): AdvancedMultiSelectItem[] {
    return [
        { name: 'Succeeded', value: 'SUCCEEDED' },
        { name: 'Failed', value: 'FAILED' }
    ];
}

export function getFileUploadRecordType(): AdvancedMultiSelectItem[] {
    return [
        { name: 'Executions', value: 'EXECUTION' },
        { name: 'Orders', value: 'ORDER' },
        { name: 'Transactions', value: 'TRANSACTION' },
        { name: 'Broker execution', value: 'BROKER_EXECUTION' }
    ];
}

export function getApplicationGenders(): AdvancedMultiSelectItem[] {
    return [
        { name: 'Male', value: 'MALE' },
        { name: 'Female', value: 'FEMALE' },
        { name: 'Non-binary', value: 'NON_BINARY' },
        { name: 'Prefer not to identify', value: 'PREFER_NOT_TO_IDENTIFY' }
    ];
}

export function getPeriodicReviewStatus(): AdvancedMultiSelectItem[] {
    return [
        { name: 'Approved after Review', value: 'APPROVED' },
        { name: 'Not reviewed', value: 'NOT_REVIEWED' },
        { name: 'Reviewed, Ready for check', value: 'READY_FOR_CHECK' },
        { name: 'Rejected after Review', value: 'REJECTED' },
        { name: 'Sent for review', value: 'SENT_FOR_REVIEW' }
    ];
}

export function getStrReportStatusTypes(): AdvancedMultiSelectItem[] {
    return [
        { name: 'Not Required', value: AlertSTRStatus.NOT_REQUIRED },
        { name: 'In Progress', value: AlertSTRStatus.IN_PROGRESS },
        { name: 'Submitted', value: AlertSTRStatus.SUBMITTED }
    ];
}

export enum TimeInStatusType {
    OnSchedule = 'On Schedule',
    BehindSchedule = 'Behind Schedule',
    OffSchedule = 'Off Schedule'
}

export const timeInStatusTypeNameMap: { [key in TimeInStatusType]: string } = {
    [TimeInStatusType.OnSchedule]: 'onSchedule',
    [TimeInStatusType.BehindSchedule]: 'behindSchedule',
    [TimeInStatusType.OffSchedule]: 'offSchedule'
};

export function getTimeInStatusItems(): AdvancedMultiSelectItem[] {
    return [
        {
            value: { fromSeconds: null, toSeconds: null },
            name: TimeInStatusType.OnSchedule,
            color: timeInStatusColor.onSchedule
        },
        {
            value: { fromSeconds: null, toSeconds: null },
            name: TimeInStatusType.BehindSchedule,
            color: timeInStatusColor.behindSchedule
        },
        {
            value: { fromSeconds: null, toSeconds: null },
            name: TimeInStatusType.OffSchedule,
            color: timeInStatusColor.offSchedule
        }
    ];
}

export function prepareAdvancedMultiSelectItemsFromStringObservable(
    request: Observable<string[]>
): Observable<AdvancedMultiSelectItem[]> {
    return request.pipe(
        filter(items => !isNullOrUndefined(items)),
        map(items => {
            return items.map(item => ({
                value: item,
                name: item
            }));
        })
    );
}
