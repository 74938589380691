import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TextWidthService {
    private divEl: HTMLDivElement;

    public getWidth(text: string, cellEl: HTMLElement): number {
        const divEl: HTMLDivElement = this.divEl || this.createDiv(cellEl);
        divEl.textContent = text;

        return this.getElementWidth(divEl);
    }

    public destroy(): void {
        if (this.divEl) {
            document.body.removeChild(this.divEl);
        }
        this.divEl?.remove();
        this.divEl = null;
    }

    private createDiv(cellEl: HTMLElement): HTMLDivElement {
        const divEL: HTMLDivElement = document.createElement('div');
        divEL.style.position = 'fixed';
        divEL.style.float = 'left';
        divEL.style.display = 'flex';
        divEL.style.visibility = 'hidden';
        divEL.style.width = 'fit-content';
        divEL.style.whiteSpace = 'nowrap';
        divEL.style.font = this.getElementFont(cellEl);
        divEL.style.touchAction = 'none';
        divEL.style.pointerEvents = 'none';
        this.divEl = divEL;

        document.body.appendChild(this.divEl);

        return divEL;
    }

    private getElementFont(el: HTMLElement): string {
        return window.getComputedStyle(el).font;
    }

    private getElementWidth(el: HTMLElement): number {
        return +window.getComputedStyle(el).width.replace('px', '');
    }
}
