import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';

import { MatListOption, MatSelectionList } from '@angular/material/list';
import { MatCheckbox } from '@angular/material/checkbox';

import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';

import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, SetFilterModel, SetFilterModelValue } from 'ag-grid-enterprise';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { LoggingService } from 'src/app/_logging/logging.service';

import { AdvancedMultiSelectItem } from 'src/app/_shared/advanced-column-setting/advanced-column-setting.model';
import { getErrorMessage } from 'src/app/_shared/shared.functions';
import { SelectionListHelper } from 'src/app/ag-grid-tables/shared/classes/selection-list-service/selection-list.helper';
import { SetValueFormatter } from 'src/app/ag-grid-tables/shared/models.shared';

export type SetFilterParam = IFilterParams & { values: () => Observable<string[]>; valueFormatter: SetValueFormatter };

@Component({
    selector: 'trds-ag-set-filter',
    templateUrl: './ag-set-filter.component.html',
    styleUrls: ['./ag-set-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgSetFilterComponent implements IFilterAngularComp, AfterViewInit, OnDestroy {
    @ViewChild(MatSelectionList) public readonly matSelectionList: MatSelectionList;
    @ViewChildren(MatListOption) public readonly matListOption: QueryList<MatListOption>;
    @ViewChild(MatCheckbox) public readonly matCheckbox: MatCheckbox;

    public readonly values$ = new BehaviorSubject<AdvancedMultiSelectItem[]>([]);
    public readonly selectedValues$ = new BehaviorSubject<AdvancedMultiSelectItem[]>([]);
    public readonly isSelectedAllChecked$ = combineLatest([this.values$, this.selectedValues$]).pipe(
        map(([values, selectedValues]) => {
            return selectedValues.length === 0 || values.length === selectedValues.length;
        })
    );
    public readonly isSelectedAllIndeterminate$ = combineLatest([this.values$, this.selectedValues$]).pipe(
        map(([values, selectedValues]) => {
            return selectedValues.length > 0 && selectedValues.length < values.length;
        })
    );
    public readonly isSelectedAllDisabled$ = combineLatest([this.values$, this.selectedValues$]).pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map(([_values, selectedValues]) => {
            return selectedValues.length === 0;
        })
    );
    public readonly isLoading$ = new BehaviorSubject<boolean>(false);
    public readonly showLoadingError$ = new BehaviorSubject<boolean>(false);

    private readonly destroy$ = new Subject<void>();
    private selectionListHelper: SelectionListHelper;
    private params: SetFilterParam;

    constructor(protected logService: LoggingService) {}

    agInit(params: SetFilterParam): void {
        this.params = params;
        this.getValues(params).subscribe(values => {
            this.setValues(values, this.values$);
        });
    }

    ngAfterViewInit(): void {
        this.selectionListHelper = new SelectionListHelper(
            this.matSelectionList,
            this.matListOption,
            this.selectedValues$
        );
        this.selectionListHelper.observeSelectionChanges(items => this.updateFilter(items));
    }

    ngOnDestroy(): void {
        this.selectionListHelper.destroy$.next();
        this.selectionListHelper.destroy$.complete();
        this.destroy$.next();
        this.destroy$.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return false;
    }

    afterGuiAttached(): void {
        this.matListOption?.first?._hostElement?.blur();
    }

    getModel(): SetFilterModel | undefined {
        const values: string[] = this.selectedValues$.value.map(item => item.value as string) as SetFilterModelValue;
        return values.length > 0
            ? {
                  filterType: 'set',
                  values
              }
            : undefined;
    }

    isFilterActive(): boolean {
        return this.selectedValues$.value.length > 0;
    }

    setModel(model: SetFilterModel): void {
        this.setValues(model?.values ? model.values : [], this.selectedValues$);
    }

    onAnyFilterChanged(): void {
        const setFilterModel: SetFilterModel = this.params.api.getFilterModel()[this.params.column.getColDef().field];
        if (setFilterModel) {
            this.selectionListHelper.markSelectedMatListOptions();
        } else {
            this.matSelectionList.deselectAll();
        }
    }

    public onSelectedAllClick(): void {
        this.matSelectionList.deselectAll();
        this.updateFilter([]);
        this.matCheckbox.checked = true;
    }

    private getFormattedValue(value: string): string {
        if (this.params.valueFormatter) {
            return this.params.valueFormatter({
                value,
                ...this.params,
                data: this.params.context,
                node: this.params.api.getRowNode(this.params.colDef.colId)
            });
        }

        return value;
    }

    private setValues(values: string[], values$: BehaviorSubject<AdvancedMultiSelectItem[]>): void {
        values$.next(values.map(value => ({ name: this.getFormattedValue(value), value } as AdvancedMultiSelectItem)));
    }

    private updateFilter(values: AdvancedMultiSelectItem[]): void {
        this.selectedValues$.next(values);
        this.params.filterChangedCallback();
    }

    private getValues(params: SetFilterParam): Observable<string[]> {
        this.isLoading$.next(true);
        return params.values().pipe(
            tap(() => this.isLoading$.next(false)),
            catchError(error => {
                this.logService.error(
                    `Failed to load list of options for ${
                        params.column.getColDef().headerName
                    }. Error: ${getErrorMessage(error)}`
                );
                this.isLoading$.next(false);
                this.showLoadingError$.next(true);
                return of([]);
            }),
            takeUntil(this.destroy$)
        );
    }
}
