// need to be sorted ASC!
export enum TimePeriod {
    ms_1 = '1ms',
    ms_10 = '10ms',
    ms_100 = '100ms',
    sec_1 = '1s',
    sec_10 = '10s',
    min_1 = '1m',
    min_10 = '10m',
    hour_1 = '1H',
    hour_6 = '6H',
    day_1 = '1D'
}

let MIN_CANDLE_SIZE: TimePeriod;

export const getMinCandleSize = (): TimePeriod => {
    return MIN_CANDLE_SIZE || TimePeriod.ms_1;
};

export const setMinCandleSize = (minPeriod: TimePeriod): void => {
    MIN_CANDLE_SIZE = minPeriod;
};

export const TimePeriodFullName = {
    [TimePeriod.ms_1]: '1 ms',
    [TimePeriod.ms_10]: '10 ms',
    [TimePeriod.ms_100]: '100 ms',
    [TimePeriod.sec_1]: '1 sec',
    [TimePeriod.sec_10]: '10 sec',
    [TimePeriod.min_1]: '1 min',
    [TimePeriod.min_10]: '10 mins',
    [TimePeriod.hour_1]: '1 hour',
    [TimePeriod.hour_6]: '6 hours',
    [TimePeriod.day_1]: '1 day'
};

export enum TimeRangeOptions {
    min_30 = '30m',
    day_1 = '1D',
    day_5 = '5D',
    month_1 = '1M',
    month_3 = '3M',
    month_6 = '6M',
    year_1 = '1Y'
}

export const TimeRangeOptionsFullName = {
    [TimeRangeOptions.min_30]: '30 minutes',
    [TimeRangeOptions.day_1]: '1 day',
    [TimeRangeOptions.day_5]: '5 days',
    [TimeRangeOptions.month_1]: '1 month',
    [TimeRangeOptions.month_3]: '3 months',
    [TimeRangeOptions.month_6]: '6 months',
    [TimeRangeOptions.year_1]: '1 year'
};

export enum MILocalStorage {
    SymbolPairsExchangesUpdateTime = 'symbolPairsExchangesUpdateTime',
    SymbolPairsExchanges = 'symbolPairsExchanges',
    MIFilterState = 'MIFilterState'
}

export type MarkerClassType =
    | 'buy-marker'
    | 'sell-marker'
    | 'canceled-marker'
    | 'new-marker'
    | 'client-marker'
    | string;

export enum ScreenSize {
    extras = 'extras',
    xxs = 'xxs',
    xs = 'xs',
    s = 's',
    m = 'm',
    xl = 'xl',
    xxl = 'xxl'
}

export enum SidebarPanelType {
    DESCRIPTION = 'DESCRIPTION',
    ORDERBOOK = 'ORDERBOOK',
    EMPTY = 'EMPTY',
    CLIENT_DATA_VIEW = 'CLIENT_DATA_VIEW'
}

export const MIN_CANDLE_AMOUNT = 7;
export const MAX_CANDLE_AMOUNT = 450;
