import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, throttleTime } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { AuthService } from './auth.service';
import { AppConfigService } from '../app-config.service';
import { ReloadService } from '../_services/reload/reload.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private throttleLogout = new Subject();
    constructor(
        private auth: AuthService,
        private config: AppConfigService,
        private router: Router,
        private reloadService: ReloadService
    ) {
        // Using the subject to make sure the router isn't called to redirect
        // multiple times due to many XHR calls returning 401 error
        this.throttleLogout.pipe(throttleTime(1500)).subscribe(url => {
            this.logout();
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // correct URL, except for assets
        const url = this.constructApiUrl(request.url);
        const token = this.auth.getToken();

        if (
            !token &&
            request.url.indexOf('/auth/') == -1 &&
            request.url.indexOf('/users/') == -1 &&
            request.url.indexOf('.') == -1
        ) {
            setTimeout(() => {
                this.logout();
            }, 100);
            return next.handle(null).pipe();
        }

        request = request.clone({
            url,
            setHeaders: {
                'X-Auth-Token': `${token}`
            }
        });
        return next.handle(request).pipe(
            catchError((response: HttpErrorResponse) => {
                // 401 unauthorized should logout only after logged in
                if (
                    response.status === 401 &&
                    response.url &&
                    !response.url.endsWith('secure-login') &&
                    !TokenInterceptor.isReAuth2FA(request)
                ) {
                    this.throttleLogout.next();
                    return throwError(response);
                } else if (response.status !== 200) {
                    // log the error message, in case the main handler didn't
                    // logging to only console to prevent an error loop, if server is down
                    console.error(`TokenInterceptor caught an error: ${response.message}`);
                    // throw the error as is, so it can be caught
                    return throwError(response);
                }
            })
        );
    }

    private static isReAuth2FA(request: HttpRequest<any>): boolean {
        return request.url.endsWith('auth-code') && JSON.parse(request.params.get('reloadOnFailure')) === false;
    }

    /**
     * Construct url based on path
     * @param path - api endpoint to call
     * @returns full api url
     */
    private constructApiUrl(path: string): string {
        const pathParts = path.split('/');
        const microserviceKey = pathParts[0];
        const cleanPath = pathParts.slice(1).join('/');
        const parts = this.config.get(microserviceKey);
        if (parts) {
            return parts.protocol + '://' + parts.subDomain + parts.domain + parts.port + '/' + cleanPath;
        }
        // fallback, in case path was not found
        return path;
    }

    private logout() {
        this.auth.logout();
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    }
}
