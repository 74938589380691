import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { LoggingService } from 'src/app/_logging/logging.service';
import { getErrorMessage } from 'src/app/_shared/shared.functions';
import { environment } from 'src/environments/environment.local';
import { UserService } from '../../users/user.service';
import { ZendeskSettings, ZendeskWidget } from './zendesk-widget.models';

declare global {
    interface Window {
        zE: ZendeskWidget;
        zESettings: ZendeskSettings;
    }
}

const zEBaseUrl = 'https://static.zdassets.com/ekr/snippet.js?key=';

@Injectable({
    providedIn: 'root'
})
export class ZEWidgetService {
    private _onClose$ = new Subject();
    public onClose$ = this._onClose$.asObservable();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private userService: UserService,
        private log: LoggingService,
        rendererFactory: RendererFactory2
    ) {
        const renderer = this.initializeRenderer2(rendererFactory);

        this.loadZEWidgetDynamically(renderer);
    }

    private initializeRenderer2(rendererFactory: RendererFactory2): Renderer2 {
        return rendererFactory.createRenderer(null, null);
    }

    public loadZEWidgetDynamically(renderer: Renderer2): void {
        const zEScriptElement = this.createZEWidgetScript(renderer, zEBaseUrl + environment.zendeskKey);

        zEScriptElement.onload = () => {
            this.initializeZendeskWidget();
        };

        zEScriptElement.onerror = () => {
            this.log.error(`There was a problem loading Zendesk widget script.`);
        };
    }

    public createZEWidgetScript(renderer: Renderer2, src: string): HTMLScriptElement {
        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.id = 'ze-snippet';
        renderer.appendChild(this.document.body, script);
        return script;
    }

    private initializeZendeskWidget(): void {
        this.initializeZESettings();
        this.hideButton();
    }

    private initializeZESettings(): void {
        try {
            window.zESettings = {
                webWidget: {
                    offset: { horizontal: '16px', vertical: '20px' },
                    position: { horizontal: 'right', vertical: 'top' },
                    color: {
                        theme: '#ffcd26',
                        button: '#ffcd26',
                        header: '#303030',
                        launcherText: '#000000'
                    },
                    contactForm: {
                        title: {
                            '*': 'Submit a Request'
                        }
                    }
                }
            };

            window.zE('webWidget:on', 'close', () => {
                // Hide the default button after close
                this.hideButton();

                // Inform subscribers that the widget was closed
                this._onClose$.next();
            });
        } catch (error) {
            this.log.error(`There was a problem initializing Zendesk widget. Error: ${getErrorMessage(error)}`);
        }
    }

    private hideButton(): void {
        try {
            window.zE('webWidget', 'hide');
        } catch (error) {
            this.log.error(
                `There was a problem hiding the initial Zendesk widget button. Error: ${getErrorMessage(error)}`
            );
        }
    }

    public open(): Observable<boolean> {
        try {
            // enable/ show the widget and open it
            window.zE('webWidget', 'show');
            window.zE('webWidget', 'open');

            // add initial data
            window.zE('webWidget', 'prefill', {
                email: {
                    value: this.userService.user?.email,
                    readOnly: true // optional
                }
            });

            return of(true);
        } catch (error) {
            this.log.error(`There was a problem opening the Zendesk widget. Error: ${getErrorMessage(error)}`);
            return throwError(error);
        }
    }

    public close(): void {
        window.zE('webWidget', 'close');
    }
}
